import { Injectable } from '@angular/core';
import { ApiClientService } from '../../../shared/services/api-client.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class EmailserviceService {

  constructor(
    public toster: ToastrService,
    private apiClientService: ApiClientService,
    private router:Router
  ) { }

  configureEmailList(data: any, isEdit: boolean = false, emailId: number = 0): Observable<any> {
      if(isEdit) {

        return this.apiClientService.put(`update_config_email/${emailId}`, data)
      } else {
        return this.apiClientService.post<any>('configure-emails', data);
      }
  }
  getConfigureEmailList(){
      return this.apiClientService.get<any>('configure-emails');
  }
  putConfigureEmail(data: any, id:number){
    return this.apiClientService.put<any>(`configure-emails/${id}`, data);
  }

  destroyConfigureEmail(id:number){
    return this.apiClientService.delete<any>(`configure-emails/${id}`);
  }
}
