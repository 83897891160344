import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiClientService } from '../../../../shared/services/api-client.service';
import { CommonService } from '../../../../shared/services/common.service';
import { AuthService } from '../../../../shared/services/firebase/auth.service';
import { EmailserviceService } from '../emailservice.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-configure-email-list',
  templateUrl: './configure-email-list.component.html',
  styleUrls: ['./configure-email-list.component.scss']
})
export class ConfigureEmailListComponent implements OnInit {
  emailList: any[] = [];
  searchTerm: string = '';
  
  emailForm: FormGroup;
  id: any
  destroy$: Subject<boolean> = new Subject<boolean>();
  result: any
  templateTypesArr: any
  action_email_id = 0;
  action_email_message = "Are you sure?";
  action = 0;
  disableSubmit: boolean = false;
  isEditView: boolean = false;
  emailId: number = 0;

  // emailTypes = ['auth', 'submission', 'all']; // Dropdown options

  constructor(
    private fb: FormBuilder, 
    private modalService: NgbModal,
    private apiClientService: ApiClientService,
    private http: HttpClient, 
    private route: ActivatedRoute, 
    private authService: AuthService,
    private emailService: EmailserviceService,
    private common: CommonService,
    private toastrService: ToastrService
  ) {
    this.id = this.route.snapshot.params.id
  }

  ngOnInit(): void {
    this.loadEmails();

    this.emailForm = this.fb.group({
      send_from: ['', [Validators.required, Validators.email]], // Email field
      tenant: ['', [Validators.required]],
      client_id: ['', [Validators.required]],
      client_secret: ['', [Validators.required]],
      default: [false], // Checkbox
    });
  }
  
  loadEmails() {
    this.emailService.getConfigureEmailList().pipe(takeUntil(this.destroy$)).subscribe(result => {
      this.emailList = result
    },
      error => {
        if (error.error['message'] == 'Unauthenticated.') {
          this.authService.clear();
        }
      });  
    }

  openEmailModal(content: TemplateRef<any>, id:number = 0, msg:string = '', action:number=0): void {
    this.action_email_id = id;
    this.action_email_message = msg;
    this.action = action;
    this.modalService.open(content, { centered: true, backdrop: 'static' });
  }

  editEmail(content: TemplateRef<any>, data: any) {
    this.emailId = data.id;
    this.emailForm.setValue({
      send_from: data.send_from || '',
      tenant: data.tenant || '',
      client_id: data.client_id || '',
      client_secret: data.client_secret || '',
      default: data.default === 'yes' // Ensure the checkbox is updated correctly
    });
  
    this.isEditView = true;
    this.modalService.open(content, { centered: true, backdrop: 'static' });
  }
  

  onSubmit(modal: any) {
    this.disableSubmit = true;
    if (this.emailForm.valid) {
      this.emailService.configureEmailList(this.emailForm.value, this.isEditView, this.emailId).pipe(takeUntil(this.destroy$)).subscribe(result => {
        this.result = result;
        this.templateTypesArr = this.result.data
        if(result?.message) {
          this.toastrService.success(result?.message, 'Success!');
        }
        this.loadEmails();

        // Close the modal
        modal.close();
        this.emailForm.reset()
        this.disableSubmit = false; 
        this.isEditView = false;
        this.emailId = 0;
      },
        error => {
          this.disableSubmit = false;
          if (error.error['message'] == 'Unauthenticated.') {
            this.authService.clear();
          } else {
            if(error.status === 422) {
              this.toastrService.error('Failed to validate credentials', error.statusText);
            }
            if(error.status === 409) {
              this.toastrService.error(error?.error?.message ? error?.error?.message : 'Unable to update the email and smtp credentials', error.statusText);
            }
          }
        });
    }
  }

  onEmailAction(id: number, email_action:number, modal: any){
    modal.close('Confirmed');
    //email_action: 1=deactivate, 2=activate, 3=set default, 4=delete
    if (id != null && (email_action === 1 || email_action === 2 || email_action === 3)) {
      let updatedData = {}; 
      if (email_action === 1) {
        updatedData = { status: true };
      } else if (email_action === 2) {
        updatedData = { status: false };
      } else if (email_action === 3) {
        updatedData = { default: 'yes' };
      }     

      this.emailService.putConfigureEmail(updatedData, id).pipe(takeUntil(this.destroy$)).subscribe(result => {
          console.log('Email configuration updated successfully:', result);
          this.loadEmails();
        },
        (error) => {
          console.error('Error updating email configuration:', error);
          if(error?.status === 400) {
            this.toastrService.error(error?.error?.message ? error?.error?.message : 'Unable to deactivate email', error?.statusText)
          }
        }
      );
    } else if (id != null && email_action === 4) {
      this.emailService.destroyConfigureEmail(id).pipe(takeUntil(this.destroy$)).subscribe(result => {
          console.log('Email configuration deleted successfully:', result);
          this.loadEmails();
        },
        (error) => {
          console.error('Error deleting email configuration:', error);
          if(error?.status === 400) {
            this.toastrService.error(error?.error?.message ? error?.error?.message : 'Unable to delete email', error?.statusText)
          }
        }
      );    
    } else {
      // Handle other cases
    }
    
  }

}
