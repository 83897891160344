import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AdminService } from 'src/app/components/admins/admin.service';
import { ApiClientService } from 'src/app/shared/services/api-client.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormsService } from 'src/app/components/forms/forms.service';
import { CookieService } from 'ngx-cookie-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/shared/services/firebase/auth.service';
import { DragulaService } from 'ng2-dragula';
import { NavService } from 'src/app/shared/services/nav.service';
import Swal from 'sweetalert2';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbDate, NgbCalendar, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
// import { environment } from '../../../../../environments/environment.prod'
import { environment } from '../../../../../environments/environment.prod'
import { CategoryService } from 'src/app/components/apps/categories/category.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
// import { debounceTime } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';


@Component({
  selector: 'app-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.scss']
})
// export interface Item {
//   id: number;
//   name: string;
// } 
export class SelectionComponent implements OnInit {

  public ClassicEditor = ClassicEditor;

  @Input() list: any[]
  @Input() DynamicColumms: any[]
  @Input() pagenum: any
  @Input() type: any
  @Input() totalcount: any
  @Input() userid: any
  @Input() book_id: any
  @Input() parent_cat: any
  @Input() edit_show: any

  @Input() bookid: any
  @Input() InviteUserArr: any
  @Input() form_id: any
  @Input() form_id2: any
  // @Input() userid:any
  @Input() FilterData: any[] = []

  @Output() getBukEmailCalled = new EventEmitter();
  @Output() getFormdata = new EventEmitter();
  user_role: any = ''
  payment_type: any = ''
  selected2: any = "All"
  showmess: any = ''
  // @Output() data: EventEmitter<string[]> = new EventEmitter<string[]>();
  invite_err: any = ''
  public BulkEmailForm: FormGroup;
  DepEmaildata: any
  userDetailID: any
  hiturl: any = ''
  userDetailEmail: any
  user_details: any = []
  user_email: any = "";
  public selectedItems = [];
  public selectedAllItems = [];
  public dropdownSettings = {};
  public dropdownSettingsInvite = {};
  dep_arr: any = []
  invite_arr: any = []
  LoaderClass: boolean = false;
  DiscountData: any = [{
    discountval: 'Yes'
  }, {
    discountval: 'No',
  }]
  id: any
  discounttype_val: any = null
  backtoPageURL: any;
  public company = [];
  public selected = [];
  arrdata: any = []
  urlval: any
  order: any
  columm: any
  result: any
  AdminArr: any
  sortarr: any
  currentPage = 1;
  pageSize: any = 25;
  pageSize2: any = 25;
  file: any
  searchemp: any
  minDob: any;
  maxDob: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  config: any;
  subid: any
  error_start: any;
  error_end: any;
  public subcategoryurl: any;
  newArr: any
  showedit: any
  showvoteedit: any
  showvoteactive: any
  showvotecreate: any
  showdetails: any
  showblock: any
  showactive: any
  showCopytext: any = ''
  showcreate: any
  showview: any
  showexport: any
  showdetail: any
  showappranddec: any
  subsubid: any
  editsubshow: boolean;
  editsubsubshow: boolean;
  countryarr: any
  draggedrow: any;
  finalid: any
  finalrows: any
  currIndexval: any
  selection: any
  nationalselect: any = "0"
  nationalselect2: any = ''
  dob: any
  date: any
  typeofuser: any = '0'
  data1: any = ''
  showdelete: any
  showvotedelete: any
  status: any = ''
  status2: any = ''
  assoval = null
  amount: any
  catval: any
  typeofuser2: any = ''
  cc: any = ''
  bcc: any = ''
  emailcontent: any
  subject: any
  to: any
  text: any
  statusarr = [
    { id: '0', name: 'inactive' },
    { id: '1', name: 'active' }
  ]
  typecusarr = [
    { value: 'registered' },
    { value: 'guest' }
  ]
  // countcatactive:number = 0
  numofrows: any = 25
  RowsArr = [
    { id: "0", value: 5 },
    { id: "1", value: 25 },
    { id: "2", value: 50 },
    { id: "3", value: 100 },
    { id: "4", value: 'All' },

  ];
  assoArr = [
    { value: 'Yes' },
    { value: 'No' }
  ]
  discount_type: any = ''
  InviteUserdata: any = [];
  startDate: any = ''
  EndDate: any = ''
  Adminroletype: any = null
  @Output() createDepchange = new EventEmitter();
  @Output() CreateForm = new EventEmitter();
  @Output() EditDepchange = new EventEmitter();
  @Output() ALLevent = new EventEmitter();
  // isDisabled = (date: NgbDate, current: {month: number}) => date.month !== current.month;
  // isWeekend = (date: NgbDate) =>  this.calendar.getWeekday(date) >= 6;

  //  onDateSelection(date: NgbDate) {
  //   if (!this.fromDate && !this.toDate) {
  //     this.fromDate = date;
  //   } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
  //     this.toDate = date;
  //   } else {
  //     this.toDate = null;
  //     this.fromDate = date;
  //   }
  // }

  // isHovered(date: NgbDate) {
  //   return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  // }

  // isInside(date: NgbDate) {
  //   return date.after(this.fromDate) && date.before(this.toDate);
  // }

  // isRange(date: NgbDate) {
  //   return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  // }
  showstatus: boolean = false
  idforemail: any = ''
  categoryLevel: number = 1
  allCategories: any[] = [];
  showSubCategoryList: any[] = [];
  showSubSubCategoryList: any[] = [];

  allSubCategories: any[] = [];
  category_name: any = ''
  subcategory_name: any = ''
  sub_sub_category_name: any = ''
  MainSearchVar: any = ''
  AllBookSearchUpdate = new Subject<string>();
  debounceTimeValue = 1000;
  abortControllers: any = null;

  constructor(private common: CommonService,
    private dragulaService: DragulaService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private formService: FormsService,
    private router: Router, private navServices: NavService, private toster: ToastrService, private authService: AuthService, private route: ActivatedRoute, private categoryService: CategoryService, private AdminsService: AdminService, private apiClientService: ApiClientService, private cookie: CookieService,
    private formservice: FormsService) {
    this.config = {
      currentPage: 1,
      itemsPerPage: 3,
    };

    // const regExp = new RegExp();

    this.BulkEmailForm = this.fb.group({
      userTo: new FormControl(null, [Validators.required]),
      cc: new FormControl(null, [Validators.pattern(/^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/)]),
      bcc: new FormControl(null, [Validators.pattern(/^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/)]),
      subject: new FormControl(null, [Validators.required]),
      content: new FormControl(null, [Validators.required]),
    });
    this.idforemail = this.route.snapshot['_routerState'].url;
    this.AllBookSearchUpdate.pipe(debounceTime(400)).subscribe((value) => {
      this.searchval(value)
    });
  }


  ResetFilters() {
    this.nationalselect = '0'
    this.status = '0'
    this.typeofuser = '0'
    this.amount = ''
    this.nationalselect2 = ''
    this.status2 = ''
    this.typeofuser2 = ''
    this.dob = ''
    this.data1 = ''
    let data = {
      perpage: this.pageSize,
      page: 1,
      columm: '',
      type: 'User',
      order: '',
      nat: this.nationalselect2,
      date: this.dob,
      amount: this.amount,
      status: this.status2,
      user_type: this.typeofuser2,
      search: ''
    }
    this.common.setreset(data)
  }
  minDobstart: any;

  startChange(e: any) {
    this.error_start = "";
    this.minDob = e.target.value;
    console.log(this.EndDate, 'epepeppe', this.startDate)
    if (this.startDate != undefined && this.EndDate != undefined && this.startDate != '' && this.EndDate != '') {
      let data14 = {
        page: 1,
        perpage: this.pageSize,
        type: "AllBook",
        columm: '',
        order: '',
        payment_type: this.payment_type,
        start_date: this.startDate,
        end_date: this.EndDate,
        search: this.MainSearchVar,
        category_name: this.category_name,
        subcategory_name: this.subcategory_name,
        sub_sub_category_name: this.sub_sub_category_name,
        discount_type: this.discount_type,

      }
      this.common.setpagenum(data14)
    }

  }
  EndChange(e: any) {
    this.error_end = "";
    this.maxDob = e.target.value;
    if (this.startDate != undefined && this.EndDate != undefined && this.startDate != '' && this.EndDate != '') {
      let data14 = {
        page: 1,
        perpage: this.pageSize,
        type: "AllBook",
        columm: '',
        order: '',
        payment_type: this.payment_type,
        start_date: this.startDate,
        end_date: this.EndDate,
        search: this.MainSearchVar,
        category_name: this.category_name,
        subcategory_name: this.subcategory_name,
        sub_sub_category_name: this.sub_sub_category_name,
        discount_type: this.discount_type,
      }
      this.common.setpagenum(data14)
    }
  }
  // getCategory(){
  //   this.formService
  //   .fetchCategoryList(this.categoryLevel)
  //   .pipe(takeUntil(this.destroy$))
  //   .subscribe(
  //     (result: any) => {
  //       this.allCategories = result["data"];

  //       this.allCategories.map((category) => {
  //         if (category.sub_categories.length > 0) {
  //           category.sub_categories.map((subCategory) => {
  //             this.allSubCategories.push(subCategory);
  //           });
  //         }
  //       });
  //     },
  //     (error) => {
  //       if (error.error["message"] == "Unauthenticated.") {
  //         this.authService.clear();
  //       }
  //     }
  //   )
  // }
  getCategory() {
    this.formService
      .fetchRoleCategoriesList()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result: any) => {
          this.allCategories = result["data"];
          this.allCategories.map((category) => {
            if (category.sub_categories.length > 0) {
              category.sub_categories.map((subCategory) => {
                this.allSubCategories.push(subCategory);
              });
            }
          });
        },
        (error) => {
          if (error.error["message"] == "Unauthenticated.") {
            this.authService.clear();
          }
        }
      )
  }
  CategoryChange(e: any, type: string) {
    console.log(e, "EEEEEEEEE")
    // this.res.category_name=e.target.value
    // this.category_name=e.target.value
    if (type == 'yes') {
      this.subcategory_name = ''
      this.sub_sub_category_name = ''

    }
    console.log(this.allSubCategories, "ALLLLLL")
    this.showSubCategoryList = this.allSubCategories.filter((el) => {
      return el.parent_id == this.category_name;
    });
    console.log(this.showSubCategoryList, "SUBBBBBBBBBBBBBBBBBBBBBB")
    if (this.showSubCategoryList.length == 0) {
      this.subcategory_name = ''
      this.sub_sub_category_name = ''
    }
    let data14 = {
      page: 1,
      perpage: this.pageSize,
      type: "AllBook",
      columm: '',
      order: '',
      payment_type: this.payment_type,
      start_date: this.startDate,
      end_date: this.EndDate,
      search: this.MainSearchVar,
      category_name: this.category_name,
      subcategory_name: this.subcategory_name,
      sub_sub_category_name: this.sub_sub_category_name,
      discount_type: this.discount_type,

    }
    // this.getForms(this.res)
    this.common.setpagenum(data14)
  }
  subCategoryChange(subCategoryId: number, type: string) {
    // this.selectedSubCategory = subCategoryId;
    // this.subcategory_name=subCategoryId
    if (type == 'yes') {
      this.sub_sub_category_name = ''

    }

    let subCategory = this.showSubCategoryList.filter((el) => {
      return el.id == this.subcategory_name;
    });
    // console.log('SELECTYED ', subCategory)
    this.showSubSubCategoryList = subCategory.length > 0 && subCategory[0]['subsubcategories'].length > 0 ? subCategory[0]['subsubcategories'] : [];
    console.log(this.showSubSubCategoryList.length, "LEEEENGGGTTTTTHHH")
    if (this.showSubSubCategoryList.length == 0) {
      this.sub_sub_category_name = ''
    }
    let data14 = {
      page: 1,
      perpage: this.pageSize,
      type: "AllBook",
      columm: '',
      order: '',
      payment_type: this.payment_type,
      start_date: this.startDate,
      end_date: this.EndDate,
      search: this.MainSearchVar,
      category_name: this.category_name,
      subcategory_name: this.subcategory_name,
      sub_sub_category_name: this.sub_sub_category_name,
      discount_type: this.discount_type,


    }
    // this.getForms(this.res)
    this.common.setpagenum(data14)


  }
  subSubCategoryChange(val) {
    this.sub_sub_category_name = val
    let data14 = {
      page: 1,
      perpage: this.pageSize,
      type: "AllBook",
      columm: '',
      order: '',
      payment_type: this.payment_type,
      start_date: this.startDate,
      end_date: this.EndDate,
      search: this.MainSearchVar,
      category_name: this.category_name,
      subcategory_name: this.subcategory_name,
      sub_sub_category_name: this.sub_sub_category_name,
      discount_type: this.discount_type,

    }
    // this.getForms(this.res)
    this.common.setpagenum(data14)
    // this.getForms(this.res)
  }
  natValidate(e: any) {
    // alert(e.target.value)

    console.log(e.target.value, "EEEEEEEEEEE", this.nationalselect)
    // this.nationalselect=e.target.value
    // if(e.target.value =='0: null' || e.target.value =='0: undefined'){
    if (e.target.value == '0') {

      this.nationalselect2 = ''
    } else {
      this.nationalselect2 = this.nationalselect

    }
    let data = {
      perpage: this.pageSize,
      page: 1,
      columm: '',
      type: 'User',
      order: '',
      nat: this.nationalselect2,
      date: this.dob,
      amount: this.amount,
      status: this.status2,
      user_type: this.typeofuser2
    }
    // if(this.nationalselect){

    // }
    // alert(this.nationalselect);
    if (this.nationalselect2 != '' || this.nationalselect2 != 0) {
      this.common.setnat(data)

    }
  }
  datevalidate(e: any) {
    this.dob = e.target.value
    let data = {
      perpage: this.pageSize,
      page: 1,
      columm: '',
      type: 'User',
      order: '',
      nat: this.nationalselect2,
      date: this.dob,
      amount: this.amount,
      status: this.status2,
      user_type: this.typeofuser2

    }
    this.common.setdate(data)

  }
  amountin(e: any) {
    this.amount = e.target.value
    let data = {
      perpage: this.pageSize,
      page: 1,
      columm: '',
      type: 'User',
      order: '',
      nat: this.nationalselect2,
      date: this.dob,
      status: this.status2,
      user_type: this.typeofuser2,
      amount: this.amount
    }
    this.common.setamount(data)
  }
  typeValidate(e: any) {
    this.typeofuser = e.target.value

    if (e.target.value == '0') {

      this.typeofuser2 = ''
    } else {
      this.typeofuser2 = e.target.value

    } let data = {
      perpage: this.pageSize,
      page: 1,
      columm: '',
      type: 'User',
      order: '',
      nat: this.nationalselect2,
      date: this.dob,
      amount: this.amount,
      status: this.status2,
      user_type: this.typeofuser2
    }
    this.common.settype(data)
  }
  statusValidate(e: any) {

    this.status = e.target.value

    if (e.target.value == '0') {

      this.status2 = ''
    } else {
      this.status2 = e.target.value

    }

    let data = {
      perpage: this.pageSize,
      page: 1,
      columm: '',
      type: 'User',
      order: '',
      nat: this.nationalselect2,
      date: this.dob,
      amount: this.amount,
      status: this.status2,
      user_type: this.typeofuser2
    }
    this.common.setstatus(data)
  }
  rowsValidate(event: any) {
    // alert("1")
    // alert('hit')
    this.currentPage = 1
    this.pageSize2 = event.target.value
    this.pageSize = event.target.value
    let data = {
      perpage: this.pageSize,
      page: 1,
      type: 'Admin',
      columm: '',
      order: '',
      filterrole: this.Adminroletype

      // search:this.data1

    }
    let data1 = {
      perpage: this.pageSize,
      page: 1,
      type: 'Form',
      columm: '',
      order: ''

    }
    let data2 = {
      perpage: this.pageSize,
      page: 1,
      type: 'User',
      columm: '',
      order: '',
      nat: this.nationalselect2,
      date: this.dob,
      amount: this.amount,
      status: this.status2,
      user_type: this.typeofuser2,

    }
    let data3 = {
      perpage: this.pageSize,
      page: 1,
      type: 'Pages',
      columm: '',
      order: ''

    }
    let data4 = {
      perpage: this.pageSize,
      page: 1,
      type: 'Email',
      columm: '',
      order: ''
    }
    let data5 = {
      perpage: this.pageSize,
      page: 1,
      type: 'Category',
      columm: '',
      order: ''

    }
    let data6 = {
      perpage: this.pageSize,
      page: 1,
      type: 'Roles',
      columm: '',
      order: ''

    }
    let data7 = {
      perpage: this.pageSize,
      page: 1,
      type: 'SubCategory',
      columm: '',
      order: ''

    }
    let data8 = {
      perpage: this.pageSize,
      page: 1,
      type: 'SubSubCategory',
      columm: '',
      order: ''

    }
    let data9 = {
      perpage: this.pageSize,
      page: 1,
      type: 'FormSubmission',
      columm: '',
      order: ''

    }

    let data10 = {
      perpage: this.pageSize,
      page: 1,
      type: 'HomepageSlider',
      columm: '',
      order: ''

    }
    let data11 = {
      perpage: this.pageSize,
      page: 1,
      type: 'dependent',
      columm: '',
      order: ''

    }
    let data12 = {
      page: 1,
      perpage: this.pageSize,
      type: "AllBook",
      columm: '',
      order: '',
      payment_type: this.payment_type,
      start_date: this.startDate,
      end_date: this.EndDate,
      search: this.MainSearchVar,
      category_name: this.category_name,
      subcategory_name: this.subcategory_name,
      sub_sub_category_name: this.sub_sub_category_name,
      discount_type: this.discount_type,
    }
    let data13 = {
      perpage: this.pageSize,
      page: 1,
      type: 'AutoGenerated',
      columm: '',
      order: ''

    }

    let data14 = {
      perpage: this.pageSize,
      page: 1,
      type: 'finance',
      columm: '',
      order: ''
    }

    let data15 = {
      perpage: this.pageSize,
      page: 1,
      type: 'FinanceRefund',
      columm: '',
      order: ''
    }
    let data16 = {
      perpage: this.pageSize,
      page: 1,
      type: 'BulkEmail',
      columm: '',
      order: ''
    }
    let data17 = {
      perpage: this.pageSize,
      page: 1,
      type: 'VotingForm',
      columm: '',
      order: ''
    }
    let data18 = {
      perpage: this.pageSize,
      page: 1,
      type: 'BookUser',
      columm: '',
      order: ''
    }
    let data20 = {
      perpage: this.pageSize,
      page: 1,
      type: 'FinanceRefund',
      columm: '',
      order: ''
    }
    if (this.type == "Admin") {
      // this.common.setpageper(data)
      this.AllPage(data)
      this.ALLevent.emit(data)

    }
    else if (this.type == 'BookUser') {
      this.common.setpagenum(data18)

    }
    else if (this.type == 'Form') {
      this.AllPage(data)

      this.common.setpageper(data1)

    }
    else if (this.type == 'User') {
      if (data2.perpage == 'All') {
        this.totalcount = 0
      }
      this.AllPage(data)

      this.common.setpageper(data2)

    }
    else if (this.type == 'Pages') {
      this.AllPage(data)

      this.common.setpageper(data3)

    }
    else if (this.type == 'Email') {
      this.AllPage(data)

      this.common.setpageper(data4)
    }
    else if (this.type == 'Category') {
      this.AllPage(data)

      this.common.setpageper(data5)
    }
    else if (this.type == 'SubCategory') {
      this.AllPage(data)

      this.common.setpageper(data7)
    }
    else if (this.type == 'Roles') {
      this.AllPage(data)

      this.common.setpageper(data6)
    }
    else if (this.type == 'SubSubCategory') {
      this.AllPage(data)

      this.common.setpageper(data8)
    }
    else if (this.type == 'FormSubmission') {
      this.AllPage(data)

      this.common.setpageper(data9)
    }
    else if (this.type == 'HomepageSlider') {
      this.AllPage(data)

      this.common.setpageper(data10)
    }
    else if (this.type == 'dependent') {
      this.AllPage(data)

      this.common.setpageper(data11)
    }
    else if (this.type == 'AllBook') {
      //  this.AllPage(data)
      // alert(data12.perpage)
      if (data12.perpage == 'All') {
        this.totalcount = 0
      }
      this.common.setpageper(data12)
    }
    else if (this.type == 'AutoGenerated') {
      this.AllPage(data)

      this.common.setpageper(data13)
    }
    else if (this.type == 'finance') {
      this.AllPage(data)

      this.common.setpageper(data14)
    }
    else if (this.type == 'FinanceRefund') {
      this.AllPage(data20)
      this.common.setpageper(data20)
    }
    else if (this.type == 'BulkEmail') {
      this.AllPage(data)

      this.common.setpageper(data16)
    }
    else if (this.type == 'VotingForm') {
      this.AllPage(data)

      this.common.setpageper(data17)
    }
  }


  duplicate(id, action) {
    // this.common.setdup(id)
    // alert('1')
    Swal.fire({
      title: "Are you sure?",
      text: 'you want to duplicate this form',
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.formservice.duplicateForm(id).pipe(takeUntil(this.destroy$)).subscribe(result => {
          this.result = result
          this.toster.success('Form Duplicated Successfully');
          this.common.setdup(id)
          let idd = result['data']['id'];
          let actionn = result['data']['form_id']
          let useid = result['data']['user_id']
          let sub_id = result['data']['submission_id']
          // 
          this.redirect(idd, actionn, useid, sub_id)


        }, error => {
          this.toster.error(error.error['message']);

          if (error.error['message'] == 'Unauthenticated.') {
            this.authService.clear();


          }
        });

      }

    })
  }

  createDependent() {
    // this.common.setcreatedep('true')
    this.createDepchange.emit(true)
  }

  onSort(event) {
    console.log('SHSHS', event)
    event.sorts.map((ind, i) => {

      this.order = ind.dir
      this.columm = ind.prop

    })
    let data = {
      page: this.currentPage,
      perpage: this.pageSize,
      type: "Admin",
      columm: this.columm,
      order: this.order
    }
    let data2 = {
      page: this.currentPage,
      perpage: this.pageSize,
      type: "User",
      columm: this.columm,
      order: this.order,
      nat: this.nationalselect2,
      date: this.dob,
      amount: this.amount,
      status: this.status2,
      user_type: this.typeofuser2,
      search: this.data1
      // nat:"",
      // date:""
    }
    let data3 = {
      page: this.currentPage,
      perpage: this.pageSize,
      type: "Form",
      columm: this.columm,
      order: this.order
    }
    let data4 = {
      page: this.currentPage,
      perpage: this.pageSize,
      type: "Pages",
      columm: this.columm,
      order: this.order
    }
    let data5 = {
      page: this.currentPage,
      perpage: this.pageSize,
      type: "Roles",
      columm: this.columm,
      order: this.order
    }
    let data6 = {
      page: this.currentPage,
      perpage: this.pageSize,
      type: "Email",
      columm: this.columm,
      order: this.order
    }
    let data7 = {
      page: this.currentPage,
      perpage: this.pageSize,
      type: "Category",
      columm: this.columm,
      order: this.order
    }
    let data8 = {
      page: this.currentPage,
      perpage: this.pageSize,
      type: "FormSubmission",
      columm: this.columm,
      order: this.order
    }
    let data9 = {
      page: this.currentPage,
      perpage: this.pageSize,
      type: "BookUser",
      columm: this.columm,
      order: this.order
    }
    let data10 = {
      page: this.currentPage,
      perpage: this.pageSize,
      type: "HomepageSlider",
      columm: this.columm,
      order: this.order
    }
    let data11 = {
      page: this.currentPage,
      perpage: this.pageSize,
      type: "dependent",
      columm: this.columm,
      order: this.order
    }
    let data12 = {
      page: this.currentPage,
      perpage: this.pageSize,
      type: "finance",
      columm: this.columm,
      order: this.order
    }
    let data13 = {
      page: this.currentPage,
      perpage: this.pageSize,
      type: "BulkEmail",
      columm: this.columm,
      order: this.order
    }
    let data14 = {
      page: this.currentPage,
      perpage: this.pageSize,
      type: "VotingForm",
      columm: this.columm,
      order: this.order
    }
    let data20 = {
      page: this.currentPage,
      perpage: this.pageSize,
      type: "FinanceRefund",
      columm: this.columm,
      order: this.order
    }

    if (this.type == "Admin") {
      this.common.setcol(data)
    }

    else if (this.type == 'User') {
      this.common.setcol(data2)
    }

    else if (this.type == 'Form') {
      this.common.setcol(data3)
    }

    else if (this.type == 'Pages') {
      this.common.setcol(data4)
    }

    else if (this.type == 'Roles') {
      this.common.setcol(data5)
    }

    else if (this.type == 'Email') {
      this.common.setcol(data6)
    }

    else if (this.type == 'Category') {
      this.common.setcol(data7)
    }

    else if (this.type == 'FormSubmission') {
      this.common.setcol(data8)
    }

    else if (this.type == 'BookUser') {
      this.common.setcol(data9)
    }

    else if (this.type == 'HomepageSlider') {
      this.common.setcol(data10)
    }
    else if (this.type == 'dependent') {
      this.common.setcol(data11)
    }
    else if (this.type == 'finance') {
      this.common.setcol(data12);
    }
    else if (this.type == 'BulkEmail') {
      this.common.setcol(data13);
    }
    else if (this.type == 'VotingForm') {
      this.common.setcol(data14);
    }
    else if (this.type == 'FinanceRefund') {
      this.common.setcol(data20);
    }
  }



  editcheck: any
  bookfilterdata: any = {}
  dummyarr: any = [{ s: 1, w: 2 }, { s: 2, w: 3 }]
  AdminRoleFilterArr: any = []
  ngOnch
  ngOnInit(): void {
    // alert('hit')
    //     setTimeout(() => {

    // console.log('count',this.totalcount);

    //     },2000)
    if (this.type == 'Admin') {
      this.common.getAdminRolesFilter().pipe(takeUntil(this.destroy$)).subscribe(result => {
        // 
        this.result = result;
        // this.common.setpagenum("")

        // 
        this.AdminRoleFilterArr = this.result.data

        // this.countryarr  =this.result.data
      },
        error => {
          this.toster.error(error.error['message']);
          // alert(error.error['message'])
          if (error.error['message'] == 'Unauthenticated.') {
            this.authService.clear();


          }
        }
      );
    }
    // alert(this.totalcount +"LIST")
    setTimeout(() => {
      // alert(this.list?.length)

      // alert("IN SELECTION"+this.totalcount)
    }, 1000)
    // this.minDobstart = moment(new Date()).format("YYYY-MM-DD");
    // this.minDob = this.minDobstart;
    // setTimeout(() => {
    // },4000)
    //All Book Filter Execute


    if (this.type == "VotingForm") {
      this.dropdownSettingsInvite = {
        singleSelection: false,
        idField: 'id',
        textField: 'email',
        enableCheckAll: true,
        selectAllText: 'Select All',
        unSelectAllText: 'Unselect All',
        allowSearchFilter: true,
        limitSelection: -1,
        clearSearchFilter: true,
        itemsShowLimit: 3,
        searchPlaceholderText: 'Search here',
        noDataAvailablePlaceholderText: 'Nothing here',
        closeDropDownOnSelection: false,
        showSelectedItemsAtTop: false,
      };
    }
    if (this.type == "FormSubmission") {
      // alert('hit')

    }
    if (this.type == 'BulkEmail') {
      this.selectedItems = [
        {
          id: this.id,
          email: this.user_email
        }
      ];
      // alert('hit2')


      this.dropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'email',
        enableCheckAll: true,
        selectAllText: 'Select All',
        unSelectAllText: 'Unselect All',
        allowSearchFilter: true,
        limitSelection: -1,
        clearSearchFilter: true,
        itemsShowLimit: 3,
        searchPlaceholderText: 'Search here',
        noDataAvailablePlaceholderText: 'Nothing here',
        closeDropDownOnSelection: false,
        showSelectedItemsAtTop: false,
      };
      // this.getBookingUsers();

    }
    if (this.type == 'AllBook') {
      this.getCategory()
      this.common.getexecutefilter().subscribe((res) => {
        if (res == true) {
          let data14 = JSON.parse(this.cookie.get('allbookfilter'))
          if (data14.payment_type != '') {
            this.status = data14.payment_type.split(',')
          }
          this.pageSize = Number(data14.perpage),
            this.pageSize2 = Number(data14.perpage),
            this.currentPage = data14.page
          // this.status=data14.payment_type
          this.EndDate = data14.end_date
          this.startDate = data14.start_date
          this.discounttype_val = data14.discount_type
          this.MainSearchVar = data14.search
          if (this.discounttype_val == '') {
            this.discounttype_val = null
          }
          if (data14.category_name != '') {
            this.category_name = Number(data14.category_name)

          }
          setTimeout(() => {
            if (data14.category_name != '') {
              this.CategoryChange(this.category_name, 'no')
            }

          }, 1000)
          if (data14.subcategory_name != '') {
            this.subcategory_name = Number(data14.subcategory_name)

          }
          setTimeout(() => {
            if (data14.category_name != '') {
              this.subCategoryChange(this.subcategory_name, 'no')
            }

          }, 1000)
          if (data14.sub_sub_category_name != '') {
            this.sub_sub_category_name = Number(data14.sub_sub_category_name)

          }

          // this.sub_sub_category_name=data14.sub_sub_category_name
          // conso
          // alert('hit1')
          // setTimeout(()=>{

          // this.common.setpagenum(data14)
          // },1000)

          // this.common.getBookFiltermaintainer().subscribe((result)=>{
          // })
        } else {
          setTimeout(() => {
            if (res != true) {

              // alert('hit2')
              // this.common.setpagenum(data14)
            }
          }, 100)

        }
      })
    }
    this.navServices.FetchPermissions().subscribe((res: any) => {
      this.newArr = res['data']['user_permissions']
      this.getPermissionsAfterVal()
      this.user_role = res['data']['user_role']
      // console.log(this.user_per,'prrrrr');
      this.common.setpermissions(this.newArr)

    }, error => {
      if (error.error['message'] == 'Unauthenticated.') {
        this.authService.clear();


      }
    })
    this.editcheck = localStorage.getItem('edit_val')

    // setTimeout(()=>{
    //   alert(this.type)
    // },4000)
    this.backtoPageURL = "/categories/subcategory-list/" + localStorage.getItem('CSD');

    this.getcountry()


    // this.common.catname.subscribe((res: any) => {
    //   
    //   this.catval=res.category_name
    // })
    this.catval = localStorage.getItem('cnajs')

    this.common.subidpass.subscribe((res: any) => {

      this.subid = res
    })

    this.common.subsubidpass.subscribe((res: any) => {
      this.subsubid = res
    })
    // this.common.setpagenum(this.config)
    let data = {
      page: 1,
      perpage: this.pageSize,
      type: "Admin",
      columm: '',
      order: '',
      // search:''
    }
    let data2 = {
      page: 1,
      perpage: this.pageSize,
      type: "Form",
      columm: '',
      order: ''
    }
    let data3 = {
      page: 1,
      perpage: this.pageSize,
      type: "User",
      columm: '',
      order: '',
      nat: "",
      date: '',
      amount: '',
      status: '',
      user_type: '',
      search: ''
    }
    let data4 = {
      page: 1,
      perpage: this.pageSize,
      type: "Page",
      columm: '',
      order: ''
    }
    let data5 = {
      page: 1,
      perpage: this.pageSize,
      type: "Email",
      columm: '',
      order: ''
    }
    let data6 = {
      page: 1,
      perpage: this.pageSize,
      type: "Category",
      columm: '',
      order: ''
    }
    let data7 = {
      page: 1,
      perpage: this.pageSize,
      type: "SubCategory",
      columm: '',
      order: ''
    }
    let data8 = {
      page: 1,
      perpage: this.pageSize,
      type: "Roles",
      columm: '',
      order: ''
    }
    let data9 = {
      page: 1,
      perpage: this.pageSize,
      type: "SubSubCategory",
      columm: '',
      order: ''
    }
    let data10 = {
      page: 1,
      perpage: this.pageSize,
      type: "FormSubmission",
      columm: '',
      order: ''
    }
    let data11 = {
      page: 1,
      perpage: this.pageSize,
      type: "BookUser",
      columm: '',
      order: ''
    }
    let data12 = {
      page: 1,
      perpage: this.pageSize,
      type: "HomepageSlider",
      columm: '',
      order: ''
    }
    let data13 = {
      page: 1,
      perpage: this.pageSize,
      type: "dependent",
      columm: '',
      order: ''
    }
    let data14 = {
      page: this.currentPage,
      perpage: this.pageSize,
      type: "AllBook",
      columm: '',
      order: '',
      payment_type: this.payment_type,
      start_date: this.startDate,
      end_date: this.EndDate,
      category_name: this.category_name,
      subcategory_name: this.subcategory_name,
      sub_sub_category_name: this.sub_sub_category_name,
      discount_type: this.discount_type,
      search: this.MainSearchVar

    }
    let data15 = {
      page: 1,
      perpage: this.pageSize,
      type: "AutoGenerated",
      columm: '',
      order: ''
    }
    let data16 = {
      page: 1,
      perpage: this.pageSize,
      type: "finance",
      columm: '',
      order: ''
    }
    let data17 = {
      page: 1,
      perpage: this.pageSize,
      type: "FinanceRefund",
      columm: '',
      order: ''
    }
    let data18 = {
      page: 1,
      perpage: this.pageSize,
      type: "BulkEmail",
      columm: '',
      order: ''
    }
    let data19 = {
      page: 1,
      perpage: this.pageSize,
      type: "VotingForm",
      columm: '',
      order: ''
    }
    let data20 = {
      page: 1,
      perpage: this.pageSize,
      type: "FinanceRefund",
      columm: '',
      order: ''
    }
    if (this.type == "Admin") {
      this.common.setpagenum(data)

    }
    else if (this.type == "VotingForm") {
      this.common.setpagenum(data19)

    }
    else if (this.type == 'Form') {
      this.common.setpagenum(data2)

    }
    else if (this.type == 'User') {
      this.common.getguestexecutefilter().subscribe((res) => {
        if (res == 'guest') {
          this.typeofuser = 'guest'
          data3.user_type = 'guest'
        } else if (res == 'user') {
          this.typeofuser = 'registered'
          data3.user_type = 'registered'


        }

      })

      this.common.setpagenum(data3)

    }
    else if (this.type == 'Pages') {
      this.common.setpagenum(data4)

    }
    else if (this.type == 'Email') {
      this.common.setpagenum(data5)
    }
    else if (this.type == 'Category') {
      this.common.setpagenum(data6)
    }
    else if (this.type == 'SubCategory') {
      this.common.setpagenum(data7)
    }
    else if (this.type == 'Roles') {
      this.common.setpagenum(data8)
    }
    else if (this.type == 'SubSubCategory') {
      this.common.setpagenum(data9)
    }
    else if (this.type == 'FormSubmission') {
      this.common.setpagenum(data10)
    }
    else if (this.type == 'BookUser') {
      this.common.setpagenum(data11)
    }
    else if (this.type == 'HomepageSlider') {
      this.common.setpagenum(data12)
    }
    else if (this.type == 'dependent') {
      this.common.setpagenum(data13)
    }
    else if (this.type == 'AllBook') {
      this.common.getguestexecutefilter().subscribe((res) => {
        if (res == 'cancel') {
          data14.payment_type = 'cancelled'
          this.payment_type = 'cancelled'
          this.status = ['cancelled']
        }
        else if (res == 'refund') {
          data14.payment_type = 'refunded'
          this.payment_type = 'refunded'
          this.status = ['refunded']
        }
      })
      this.common.setpagenum(data14)

    }
    else if (this.type == 'AutoGenerated') {
      this.common.setpagenum(data15)
    }
    else if (this.type == 'finance') {
      this.common.setpagenum(data16)

    }
    else if (this.type == 'FinanceRefund') {
      this.common.setpagenum(data20)
    }
    else if (this.type == 'BulkEmail') {
      this.common.setpagenum(data18)

    }


    this.common.urlroute.subscribe((res: any) => {
      this.urlval = res;
    })


  }
  DiscountFilter(e: any) {
    // alert("UNDER DEVELOPMENT")

    this.discount_type = this.discounttype_val
    if (this.discount_type == null) {
      this.discount_type = ''
    }
    let data14 = {
      page: 1,
      perpage: this.pageSize,
      type: "AllBook",
      columm: '',
      order: '',
      payment_type: this.payment_type,
      start_date: this.startDate,
      end_date: this.EndDate,
      search: this.data1,
      category_name: this.category_name,
      subcategory_name: this.subcategory_name,
      sub_sub_category_name: this.sub_sub_category_name,
      discount_type: this.discount_type,
    }
    this.common.setpagenum(data14)
  }
  getPermissionsAfterVal() {
    {
      // console.log(this.newArr,"++++++++++++++++++++++++++++++++++++++++++++++")
      if (this.type == 'Admin') {
        this.adminpermission()
      }
      else if (this.type == "User") {
        // alert()
        this.userPermission()
      }
      else if (this.type == "Roles") {
        this.RolesPermissions()
      }
      else if (this.type == "Form") {
        this.formsPermissions()
      }
      else if (this.type == "FormSubmission") {
        this.formsPermissions()
      }
      else if (this.type == 'Pages') {
        this.PagePermission()
      }
      else if (this.type == 'Email') {
        this.EmailPermission()
      }
      else if (this.type == 'Category') {
        // alert('asdas')
        this.CategoryPermission()
      }
      else if (this.type == 'finance') {
        this.financepermission();

      }
      else if (this.type == 'SubCategory') {
        this.SubCategoryPermissions()
      }
      else if (this.type == 'SubSubCategory') {
        this.SubSubCategoryPermissions()
      }

      else if (this.type == 'HomepageSlider') {
        this.HomepageSliderPermissions()
      }
      else if (this.type == 'dependent') {
        this.dependentPermissions()
      }
      else if (this.type == 'AllBook') {
        this.BookingDetailsPermissions()
      }
      else if (this.type == 'FinanceRefund') {
        this.FinanceRefundPermissions()
      }

    }

  }
  HomepageSliderPermissions() {
    if (this.newArr.some((el) => el.name == "create-homepage-slider")) {
      this.showcreate = "true";
    }
    if (this.newArr.some((el) => el.name == "edit-homepage-slider")) {
      this.showedit = "true";
    }
    if (this.newArr.some((el) => el.name == "change-status-homepage-slider")) {
      this.showactive = "true";
    }
  }
  BookingDetailsPermissions() {
    if (this.newArr.some((el) => el.name == "edit-any-admin")) {
      this.showedit = "true";
    }
    if (this.newArr.some((el) => el.name == "see-booking-detail")) {
      this.showdetails = "true";
    }
    // alert(this.showdetails)
  }
  dependentPermissions() {
    if (this.newArr.some((el) => el.name == "see-booking-detail")) {
      this.showedit = "true";
    }
    if (this.newArr.some((el) => el.name == "change-status-of-admin")) {
      this.showactive = "true";
    }
  }
  adminpermission() {
    if (this.newArr.some((el) => el.name == "edit-any-admin")) {
      this.showedit = "true";
    }
    if (this.newArr.some((el) => el.name == "block/unblock-the-admin")) {
      this.showblock = "true";
    }
    if (this.newArr.some((el) => el.name == "change-status-of-admin")) {
      this.showactive = "true";
    }
    if (this.newArr.some((el) => el.name == "create-admin")) {
      this.showcreate = "true";
    }
    if (this.newArr.some((el) => el.name == "see-admin-detail")) {
      this.showview = "true";
    }
    // console.log(this.newArr,"NEWWWW")
    if (this.newArr.some((el) => el.name == "export-admins")) {
      this.showexport = "true";
    }
  }
  userPermission() {


    if (this.newArr.some(el => el.name == 'edit-the-user')) {
      this.showedit = 'true'
  

      // this.sendEmail.emit()


    }
    if (this.newArr.some(el => el.name == 'block/unblock-the-users')) {
      this.showblock = 'true'
    }
    if (this.newArr.some(el => el.name == 'change-status-of-users')) {
      this.showactive = 'true'
    }
    if (this.newArr.some(el => el.name == 'create-user')) {
      this.showcreate = 'true'
    }
    if (this.newArr.some(el => el.name == 'see-user-detail')) {
      this.showview = 'true'
    }
    //  Delete the user
    if (this.newArr.some(el => el.name == 'delete-the-user')) {
      this.showdelete = 'true'
    }
  }
  RolesPermissions() {
    console.log(this.newArr, "ROLE_PERMISS")
    if (this.newArr.some(el => el.name == 'edit-Role')) {
      this.showedit = 'true'
    }
    if (this.newArr.some(el => el.name == 'delete-Role')) {
      this.showdelete = 'true'
    }
    if (this.newArr.some(el => el.name == 'create-Role')) {
      this.showcreate = 'true'
    }
    if (this.newArr.some(el => el.name == 'see-role-detail')) {
      this.showview = 'true'
    }
  }
  formsPermissions() {
    if (this.newArr.some(el => el.name == 'edit-forms')) {
      this.showedit = 'true'
    }
    if (this.newArr.some(el => el.name == 'change-form-status')) {
      this.showactive = 'true'
    }
    if (this.newArr.some(el => el.name == 'create-form')) {
      this.showcreate = 'true'
      this.CreateForm.emit(true)
    }
    //  alert(this.showcreate)
    if (this.newArr.some(el => el.name == 'delete-forms')) {
      this.showdelete = 'true'
    }
    if (this.newArr.some(el => el.name == 'delete-voting-form')) {
      this.showvotedelete = 'true'
    }
    if (this.newArr.some(el => el.name == 'edit-voting-form')) {
      this.showvoteedit = 'true'
    }
    if (this.newArr.some(el => el.name == 'change-status-own-forms-only')) {
      this.showvoteactive = 'true'
    }
    if (this.newArr.some(el => el.name == 'create-voting-form')) {
      this.showvotecreate = 'true'
    }
    if (this.newArr.some(el => el.name == 'see-form-detail')) {
      // alert('s')
      this.showdetail = 'true'
    }
  }
  PagePermission() {
    if (this.newArr.some(el => el.name == 'edit-page')) {
      this.showedit = 'true'
    }
    if (this.newArr.some(el => el.name == 'create-page')) {
      this.showcreate = 'true'
    }
    if (this.newArr.some(el => el.name == 'change-page-status')) {
      this.showactive = 'true'
    }
    if (this.newArr.some(el => el.name == 'delete-page')) {
      this.showdelete = 'true'
    }
    if (this.newArr.some(el => el.name == 'see-page-detail')) {
      this.showview = 'true'
    }
  }
  EmailPermission() {
    if (this.newArr.some(el => el.name == 'edit-email-template')) {
      this.showedit = 'true'
    }
    if (this.newArr.some(el => el.name == 'create-email-template')) {
      this.showcreate = 'true'
    }
    if (this.newArr.some(el => el.name == 'change-email-status')) {
      this.showactive = 'true'
    }

  }
  financepermission() {
    if (this.newArr.some(el => el.name == 'create-finance-types')) {
      this.showcreate = 'true'
    }

    if (this.newArr.some(el => el.name == 'edit-finance-types')) {
      this.showedit = 'true'

    }

    if (this.newArr.some(el => el.name == 'change-status-finance-types')) {
      this.showactive = 'true'
    }
  }
  CategoryPermission() {

    if (this.newArr.some(el => el.name == 'edit-category')) {
      this.showedit = 'true'

    }
    if (this.newArr.some(el => el.name == 'create-category')) {
      this.showcreate = 'true'
    }
    if (this.newArr.some(el => el.name == 'change-category-status')) {
      this.showactive = 'true'
    }
    if (this.newArr.some(el => el.name == 'delete-category')) {
      this.showdelete = 'true'
    }
    if (this.newArr.some(el => el.name == 'see-category-detail')) {
      this.showview = 'true'
    }

  }




  SubCategoryPermissions() {
    if (this.newArr.some(el => el.name == 'edit-category')) {
      this.showedit = 'true'



    }
    if (this.newArr.some(el => el.name == 'create-category')) {
      this.showcreate = 'true'

    }
    if (this.newArr.some(el => el.name == 'change-category-status')) {
      this.showactive = 'true'
    }
    if (this.newArr.some(el => el.name == 'delete-category')) {
      this.showdelete = 'true'
    }
    if (this.newArr.some(el => el.name == 'see-category-detail')) {
      this.showview = 'true'
    }
  }
  SubSubCategoryPermissions() {
    if (this.newArr.some(el => el.name == 'edit-category')) {
      this.showedit = 'true'
    }
    if (this.newArr.some(el => el.name == 'create-category')) {
      this.showcreate = 'true'
    }
    if (this.newArr.some(el => el.name == 'change-category-status')) {
      this.showactive = 'true'
    }
    if (this.newArr.some(el => el.name == 'delete-category')) {
      this.showdelete = 'true'
    }
    if (this.newArr.some(el => el.name == 'see-category-detail')) {
      this.showview = 'true'
    }
  }
  FinanceRefundPermissions() {
    if (this.newArr.some(el => el.name == 'approve/decline-refunds')) {
      this.showappranddec = 'true'
    }
  }
  downloadURI(uri: any, name: any) {
    let link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  getcountry() {
    this.common.CountryList().pipe(takeUntil(this.destroy$)).subscribe(result => {
      this.result = result;
      this.countryarr = this.result.data
      this.countryarr.forEach((count, i) => {
        count.country_code = count.country_code.toLowerCase()
      })
      this.countryarr.unshift({ id: '0', country_name: "Nationality" })

    }
    );
  }
  getAdminexceldow() {
    let data = {
      perpage: this.pageSize,
      page: this.currentPage,
      type: 'Admin',
      col: '',
      ord: '',
      nat: this.nationalselect2,
      role: this.Adminroletype,
      search: this.data1
    }

    this.AdminsService.exportAdmins(data).pipe(takeUntil(this.destroy$)).subscribe(result => {
      this.result = result
      this.file = this.result.file_path
      if (this.file != "") {
        let name = this.file.split("/");
        this.downloadURI(this.file, name[name.length - 1]);
      }
    }, error => {
      // this.error(error);

      if (error.error['message'] == 'Unauthenticated.') {
        this.authService.clear();

      }

    });
  }
  getUserexceldow() {
    let data = {
      perpage: this.pageSize,
      page: this.currentPage,
      type: 'User',
      col: '',
      ord: '',
      nat: this.nationalselect2,
      date: this.dob,
      amount: this.amount,
      status: this.status2,
      user_type: this.typeofuser2,
      search: this.data1
    }
    if (data.amount == undefined) {
      data.amount = ''
    }
    if (data.date == undefined) {
      data.date = ''
    }
    this.AdminsService.exportusers(data).pipe(takeUntil(this.destroy$)).subscribe(result => {
      this.result = result
      this.file = this.result.file_path
      if (this.file != "") {
        let name = this.file.split("/");
        this.downloadURI(this.file, name[name.length - 1]);
      }
    }, error => {
      // this.error(error);

      if (error.error['message'] == 'Unauthenticated.') {
        this.authService.clear();

      }

    });
  }
  getUserBookings() {
    console.log(this.userid, "IDDDDDDDDDDDDD")
    let data = {
      user_id: this.userid,
      perpage: this.pageSize,
      page: this.currentPage,
      type: 'User',
      col: '',
      ord: '',
      search: this.data1
    }

    this.AdminsService.exportbookusers(data).pipe(takeUntil(this.destroy$)).subscribe(result => {
      this.result = result
      this.file = this.result.file_path
      if (this.file != "") {
        let name = this.file.split("/");
        this.downloadURI(this.file, name[name.length - 1]);
      }
    }, error => {
      // this.error(error);

      if (error.error['message'] == 'Unauthenticated.') {
        this.authService.clear();

      }

    });
  }
  getAllUserBookings() {
    let data = {
      page: this.currentPage,
      perpage: this.pageSize,
      type: "AllBook",
      columm: '',
      order: '',
      payment_type: this.payment_type,
      start_date: this.startDate,
      end_date: this.EndDate,
      search: this.MainSearchVar,
      category_name: this.category_name,
      subcategory_name: this.subcategory_name,
      sub_sub_category_name: this.sub_sub_category_name,
      discount_type: this.discount_type,

    }
    this.AdminsService.exportAllbookusers(data).pipe(takeUntil(this.destroy$)).subscribe(result => {
      this.result = result
      this.file = this.result.file_path
      if (this.file != "") {
        let name = this.file.split("/");
        this.downloadURI(this.file, name[name.length - 1]);
      }
    }, error => {
      // this.error(error);

      if (error.error['message'] == 'Unauthenticated.') {
        this.authService.clear();

      }

    });
  }
  getvotingexport() {
    let data = {
      page: this.currentPage,
      perpage: this.pageSize,
      type: "AllBook",
      columm: '',
      order: '',
      start_date: this.startDate,
      end_date: this.EndDate,
      search: this.MainSearchVar,
      category_name: this.category_name,
      subcategory_name: this.subcategory_name,
      sub_sub_category_name: this.sub_sub_category_name,

    }
    this.AdminsService.exportAllVote(data).pipe(takeUntil(this.destroy$)).subscribe(result => {
      this.result = result
      this.file = this.result.file_path
      if (this.file != "") {
        let name = this.file.split("/");
        this.downloadURI(this.file, name[name.length - 1]);
      }
    }, error => {
      // this.error(error);

      if (error.error['message'] == 'Unauthenticated.') {
        this.authService.clear();

      }

    });
  }
  getList() {
    let data = {
      form_id: this.cookie.get('fzsd')
    }
    this.AdminsService.exportlist(data).pipe(takeUntil(this.destroy$)).subscribe(result => {
      this.result = result
      this.file = this.result.file_path
      if (this.file != "") {
        let name = this.file.split("/");
        this.downloadURI(this.file, name[name.length - 1]);
      }
    }, error => {
      // this.error(error);

      if (error.error['message'] == 'Unauthenticated.') {
        this.authService.clear();

      }

    });
  }
  Delete(id) {

    // this.router.navigate
    this.common.setdel(id)

  }
  DeleteForm(id) {
    this.common.setformdel(id)

  }
  DeleteFinance(id) {
    this.common.setfinancedel(id)

  }
  AllPage(data: any) {
    if (data.perpage == 'All') {
      data.page = 1
      this.pageSize = ''
      this.pageshift(1)
    }
  }
  pageshift(e: any) {

    this.currentPage = e
    let data = {
      page: e,
      perpage: this.pageSize,
      type: "Admin",
      search: this.data1,
      columm: '',
      order: '',
      filterrole: this.Adminroletype

    }
    let data2 = {
      page: e,
      perpage: this.pageSize,
      type: "Form",
      columm: '',
      order: ''

    }
    let data3 = {
      page: e,
      perpage: this.pageSize,
      type: "User",
      columm: '',
      order: '',
      nat: this.nationalselect2,
      date: this.dob,
      amount: this.amount,
      status: this.status2,
      user_type: this.typeofuser2,
      search: this.data1
    }
    let data4 = {
      page: e,
      perpage: this.pageSize,
      type: "Page",
      columm: '',
      order: ''
    }
    let data5 = {
      page: e,
      perpage: this.pageSize,
      type: "Email",
      columm: '',
      order: ''
    }
    let data6 = {
      page: e,
      perpage: this.pageSize,
      type: "Category",
      columm: '',
      order: ''
    }
    let data7 = {
      page: e,
      perpage: this.pageSize,
      type: "SubCategory",
      columm: '',
      order: ''
    }
    let data8 = {
      page: e,
      perpage: this.pageSize,
      type: "Roles",
      columm: '',
      order: ''
    }
    let data9 = {
      page: e,
      perpage: this.pageSize,
      type: "SubSubCategory",
      columm: '',
      order: ''
    }
    let data10 = {
      page: e,
      perpage: this.pageSize,
      type: "FormSubmission",
      columm: '',
      order: ''
    }
    let data11 = {
      page: e,
      perpage: this.pageSize,
      type: "BookUser",
      columm: '',
      order: ''
    }
    let data12 = {
      page: e,
      perpage: this.pageSize,
      type: "HomepageSlider",
      columm: '',
      order: ''
    }
    let data13 = {
      page: e,
      perpage: this.pageSize,
      type: "dependent",
      columm: '',
      order: ''
    }
    let data14 = {
      page: e,
      perpage: this.pageSize,
      type: "LastBookings",
      columm: '',
      order: ''
    }
    let data16 = {
      page: e,
      perpage: this.pageSize,
      type: "finance",
      columm: '',
      order: ''
    }
    let data15 = {
      page: e,
      perpage: this.pageSize,
      type: "AllBook",
      columm: '',
      order: '',
      payment_type: this.payment_type,
      start_date: this.startDate,
      end_date: this.EndDate,
      category_name: this.category_name,
      subcategory_name: this.subcategory_name,
      sub_sub_category_name: this.sub_sub_category_name,
      discount_type: this.discount_type,
    }
    let data20 = {
      page: e,
      perpage: this.pageSize,
      type: "FinanceRefund",
      columm: '',
      order: ''
    }
    let data18 = {
      page: e,
      perpage: this.pageSize,
      type: "BulkEmail",
      columm: '',
      order: ''
    }
    let data19 = {
      page: e,
      perpage: this.pageSize,
      type: "VotingForm",
      columm: '',
      order: ''
    }

    if (this.type == "Admin") {
      this.common.setpagenum(data)

    }

    else if (this.type == 'Form') {
      this.common.setpagenum(data2)

    }
    else if (this.type == 'User') {
      // alert('hit1')
      this.common.setpagenum(data3)

    }
    else if (this.type == 'Pages') {
      this.common.setpagenum(data4)

    }
    else if (this.type == 'Email') {
      this.common.setpagenum(data5)

    }
    else if (this.type == 'Category') {
      this.common.setpagenum(data6)

    }
    else if (this.type == 'SubCategory') {
      this.common.setpagenum(data7)
    }
    else if (this.type == 'Roles') {

      this.common.setpagenum(data8)
    }
    else if (this.type == 'SubSubCategory') {
      this.common.setpagenum(data9)

    }

    else if (this.type == 'FormSubmission') {
      this.common.setpagenum(data10)

    }
    else if (this.type == 'BookUser') {
      this.common.setpagenum(data11)

    }
    else if (this.type == 'HomepageSlider') {
      this.common.setpagenum(data12)

    }
    else if (this.type == 'dependent') {
      this.common.setpagenum(data13)

    }
    else if (this.type == 'LastBookings') {
      this.common.setpagenum(data14)

    }
    else if (this.type == 'AllBook') {
      this.common.setpagenum(data15)
    }
    else if (this.type == 'finance') {
      this.common.setpagenum(data16)
    }
    else if (this.type == 'FinanceRefund') {
      this.common.setpagenum(data20)
    }
    else if (this.type == 'BulkEmail') {
      this.common.setpagenum(data18)
    }
    else if (this.type == 'VotingForm') {
      this.common.setpagenum(data19)
    }
  }
  searchval(e: any) {
    this.data1 = e;
    if (this.pageSize === 'All') {
      this.pageSize = '';
    }

    this.abortControllers = this.abortControllers || {};
  
    const commonErrorHandler = (error: any) => {
      if (error.error?.message === 'Unauthenticated.') {
        this.authService.clear();
      }
    };
  
    const setCommonResults = (result: any) => {
      this.result = result;
      this.list = result.data;
      this.totalcount = result.meta.pagination.total;
    };

    const executeRequest = (serviceFunction: (signal: AbortSignal) => void) => {
      if (this.abortControllers[this.type]) {
        this.abortControllers[this.type].abort();
      }
      const controller = new AbortController();
      this.abortControllers[this.type] = controller;

      serviceFunction(controller.signal);
    };
  
    const serviceMap: Record<string, Function> = {
      'Admin': (signal: AbortSignal) => {
        this.pagenum = 1;
        this.pageSize = this.pageSize2 = 25;
        this.common.setsearch(this.data1);
        this.AdminsService.searchadmiapi(this.data1, this.type, this.pagenum, this.pageSize, signal)
          .pipe(takeUntil(this.destroy$), debounceTime(this.debounceTimeValue))
          .subscribe(setCommonResults, commonErrorHandler);
      },
      'FinanceRefund': (signal: AbortSignal) => {
        this.AdminsService.serachforallfinance(this.data1, this.pagenum, this.pageSize, signal)
          .pipe(takeUntil(this.destroy$), debounceTime(this.debounceTimeValue))
          .subscribe(setCommonResults, commonErrorHandler);
      },
      'Category': (signal: AbortSignal) => {
        this.AdminsService.searchapicat(this.data1, this.pagenum, this.pageSize, signal)
          .pipe(takeUntil(this.destroy$), debounceTime(this.debounceTimeValue))
          .subscribe(setCommonResults, commonErrorHandler);
      },
      'finance': (signal: AbortSignal) => {
        this.AdminsService.serachforreturnfinance(this.data1, this.pagenum, this.pageSize, signal)
          .pipe(takeUntil(this.destroy$), debounceTime(this.debounceTimeValue))
          .subscribe(setCommonResults, commonErrorHandler);
      },
      'Pages': (signal: AbortSignal) => {
        this.AdminsService.searchpages(this.data1, this.pagenum, this.pageSize, signal)
          .pipe(takeUntil(this.destroy$), debounceTime(this.debounceTimeValue))
          .subscribe(setCommonResults, commonErrorHandler);
      },
      'Email': (signal: AbortSignal) => {
        this.AdminsService.searchemail(this.data1, this.pagenum, this.pageSize, signal)
          .pipe(takeUntil(this.destroy$), debounceTime(this.debounceTimeValue))
          .subscribe(setCommonResults, commonErrorHandler);
      },
      'Roles': (signal: AbortSignal) => {
        this.AdminsService.searchroles(this.data1, this.pagenum, this.pageSize, signal)
          .pipe(takeUntil(this.destroy$), debounceTime(this.debounceTimeValue))
          .subscribe(setCommonResults, commonErrorHandler);
      },
      'SubCategory': (signal: AbortSignal) => {
        this.AdminsService.searchapisubcat(this.data1, this.cookie.get('ctzz'), this.pagenum, this.pageSize, signal)
          .pipe(takeUntil(this.destroy$), debounceTime(this.debounceTimeValue))
          .subscribe(setCommonResults, commonErrorHandler);
      },
      'SubSubCategory': (signal: AbortSignal) => {
        this.AdminsService.searchapisubsubcat(this.data1, this.cookie.get('sctzz'), this.pagenum, this.pageSize, signal)
          .pipe(takeUntil(this.destroy$), debounceTime(this.debounceTimeValue))
          .subscribe(setCommonResults, commonErrorHandler);
      },
      'FormSubmission': (signal: AbortSignal) => {
        const data = { form_id: this.cookie.get('fzsd') };
        this.AdminsService.searchformsubmit(this.data1, this.pagenum, this.pageSize, data, signal)
          .pipe(takeUntil(this.destroy$), debounceTime(this.debounceTimeValue))
          .subscribe(setCommonResults, commonErrorHandler);
      },
      'BookUser': (signal: AbortSignal) => {
        this.AdminsService.searchforuserbook(this.data1, this.pagenum, this.pageSize, this.cookie.get('cczd'), signal)
          .pipe(takeUntil(this.destroy$), debounceTime(this.debounceTimeValue))
          .subscribe(setCommonResults, commonErrorHandler);
      },
      'dependent': (signal: AbortSignal) => {
        this.AdminsService.searchfordependent(this.data1, this.pagenum, this.pageSize, this.cookie.get('cczd'), signal)
          .pipe(takeUntil(this.destroy$), debounceTime(this.debounceTimeValue))
          .subscribe(setCommonResults, commonErrorHandler);
      },
      'AllBook': (signal: AbortSignal) => {
        this.hiturl = this.book_id === 'votes-list' ? 'voting_form' : 'standard_form';
        this.AdminsService.searchforAllBook(this.data1, this.pagenum, this.pageSize, this.cookie.get('cczd'), this.hiturl, signal)
          .pipe(takeUntil(this.destroy$), debounceTime(this.debounceTimeValue))
          .subscribe(setCommonResults, commonErrorHandler);
      },
      'AutoGenerated': (signal: AbortSignal) => {
        const dataemail = {
          search: this.data1,
          rows: this.pageSize,
          page: this.pagenum,
          user_id: this.userid,
          booking_id: this.bookid,
        };
        this.AdminsService.searchforAutoEmails(dataemail, signal)
          .pipe(takeUntil(this.destroy$), debounceTime(this.debounceTimeValue))
          .subscribe(setCommonResults, commonErrorHandler);
      },
      'VotingForm': (signal: AbortSignal) => {
        const data = { form_id: this.form_id };
        this.AdminsService.searchforInvite(data, this.data1, this.pagenum, this.pageSize, this.cookie.get('cczd'), signal)
          .pipe(takeUntil(this.destroy$), debounceTime(this.debounceTimeValue))
          .subscribe(setCommonResults, commonErrorHandler);
      },
      'HomepageSlider': (signal: AbortSignal) => {
        this.AdminsService.searchhomepageslider(this.data1, this.pagenum, this.pageSize, signal)
          .pipe(takeUntil(this.destroy$), debounceTime(this.debounceTimeValue))
          .subscribe(setCommonResults, commonErrorHandler);
      },
      'User': () => {
        let data = {
          perpage: this.pageSize,
          page: 1,
          type: 'User',
          columm: '',
          order: '',
          nat: this.nationalselect2,
          date: this.dob,
          amount: this.amount,
          status: this.status2,
          user_type: this.typeofuser2,
          search: this.data1
        }
        this.common.setsearch(data)
      }
    };
    if (serviceMap[this.type]) {
      executeRequest((signal: AbortSignal) => serviceMap[this.type](signal));
    } else {
      console.warn('No handler found for type:', this.type);
    }
  }
  changeStatus(status, id) {
    let data1 = {
      status: status,
      user_id: id,
      admin: 'admin',
      change: 1
    };
    let data2 = {
      status: status,
      user_id: id,
      user: 'user',
      change: 1
    };
    let data3 = {
      status: status,
      page_id: id,
      page: 'page',
      change: 1
    };
    let data4 = {
      status: status,
      form_id: id,
      form: 'form',
      change: 1
    };
    let data5 = {
      status: status,
      email_id: id,
      email: 'email',
      change: 1
    };
    let data6 = {
      status: status,
      category_id: id,
      category: 'category',
      change: 1,
      statcount: '0'
    };
    let data7 = {
      status: status,
      subcategory_id: id,
      subcategory: 'subcategory',
      change: 1
    };
    let data8 = {
      status: status,
      category_id: id,
      category: 'subsubcategory',
      change: 1
    };

    let data9 = {
      status: status,
      slider_id: id,
      slider: 'slider',
      change: 1
    };
    let data10 = {
      status: status,
      dep_id: id,
      dep: 'dep',
      change: 1
    };
    let data11 = {
      status: status,
      finance_id: id,
      category: 'finance',
      change: 1
    }

    if (this.type == 'Admin') {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false,
      })
      swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text: "You want be change the status!",
        // type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          // alert("true")

          this.common.setapi(data1)


        }

      })
    }
    else if (this.type == 'User') {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false,
      })
      swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text: "You want be change the status!",
        // type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          // alert("true")

          this.common.setapi(data2)


        }

      })
    }
    else if (this.type == 'Pages') {
      if (status == '2') {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
          },
          buttonsStyling: false,
        })
        swalWithBootstrapButtons.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          // type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it !',
          cancelButtonText: 'No, cancel !',
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            // alert("true")

            this.common.setapi(data3)


          }

        })
      } else {

        this.common.setapi(data3)
      }
    }
    else if (this.type == 'Form') {
      this.common.setapi(data4)
    }
    else if (this.type == 'Email') {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false,
      })
      swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text: "You want be change the status!",
        // type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          // alert("true")

          this.common.setapi(data5)


        }

      })
    }
    else if (this.type == 'Category') {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false,
      })
      swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text: "You want be change the status!",
        // type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {

          this.common.setapi(data6)


        }

      })

      // }
    }
    else if (this.type == 'SubCategory') {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false,
      })
      swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text: "You want be change the status!",
        // type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {

          this.common.setapi(data7)


        }

      })
    }
    else if (this.type == 'SubSubCategory') {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false,
      })
      swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text: "You want be change the status!",
        // type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {

          this.common.setapi(data8)


        }

      })
    }
    else if (this.type == 'HomepageSlider') {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false,
      })
      swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text: "You want be change the status!",
        // type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {

          this.common.setapi(data9)


        }

      })
    }
    else if (this.type == 'dependent') {
      this.common.setapi(data10)
    }
    else if (this.type == 'finance') {
      this.common.setapi(data11);

    }

  }

  blockUnblock(status, id) {
    let data1 = {
      b_status: status,
      user_id: id,
      admin: 'admin',
      block: 1
    };
    let data2 = {
      b_status: status,
      user_id: id,
      user: 'user',
      block: 1
    };
    if (this.type == 'Admin') {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false,
      })
      swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text: "You want be block/unblock the status!",
        // type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {

          this.common.setapi(data1)


        }

      })

    } else if (this.type == 'User') {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false,
      })
      swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text: "You want be block/unblock the status!",
        // type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {

          this.common.setapi(data2)


        }

      })
    }


  }
  changeblock(val, type) {
    console.log('type', type)
    if (this.selected.length == 0) {
    } else {
      let multiId = [];
      this.selected.map((x) => {
        multiId.push(x.id);

      });

      let data1 = {
        // b_status: val,
        user_id: multiId.join(","),
        admin: 'admin',
        block: 1
      };
      let data2 = {
        b_status: val,
        user_id: multiId.join(","),
        user: 'user',
        block: 1
      };
      if (this.type == 'Admin') {
        if (type == 'block') {
          data1['b_status'] = val
        } else {
          data1['a_status'] = val
        }
        this.common.setapi(data1)

      } else if (this.type == 'User') {
        this.common.setapi(data2)
      }
    }
  }
  changeAllStatus(val) {
    if (this.selected.length == 0) {
    } else {
      let multiId = [];
      this.selected.map((x) => {
        multiId.push(x.id);

      });

      let data1 = {
        b_status: val,
        user_id: multiId.join(","),
        admin: 'admin',
        block: 1
      };
      let data2 = {
        b_status: val,
        user_id: multiId.join(","),
        user: 'user',
        block: 1
      };
      if (this.type == 'Admin') {
        this.common.setapi(data1)

      } else if (this.type == 'User') {
        this.common.setapi(data2)
      }
    }
  }
  redirecttoDetail(id) {
    // window.location.href= environment.frontEndFormUrl +'/' +id
    // this.open_form_link=environment.frontEndFormUrl +'/' +id
    window.open(window.location.href = environment.frontEndFormUrl + '/' + id, '_blank');

  }
  redirectform(row) {

    if (row.edit_access_enable == '1' && row.view_access_enable == '1') {
      localStorage.setItem('edit_val', '01')
      // this.router.navigate(["form/manage-forms/form-group/" + row.id]);

    } else if (row.edit_access_enable != '1' && row.view_access_enable != '1') {
      // localStorage.set('edit_val','00')

    } else if (row.edit_access_enable != '1' && row.view_access_enable == '1') {
      localStorage.setItem('edit_val', '02')
      // this.router.navigate(["form/manage-forms/form-group/" + row.id]);


    } else if (row.edit_access_enable == '1' && row.view_access_enable != '1') {
      // localStorage.set('edit_val','00')

    }
    // this.router.navigate(["form/manage-forms/form-group/" + row.id]);
    // }
  }

  deleteCategory(id, action) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      // type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it !',
      cancelButtonText: 'No, cancel !',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

        this.categoryService.deleteCategory(id).pipe(takeUntil(this.destroy$)).subscribe(result => {

          Swal.fire({
            position: 'center',
            icon: 'info',
            html: result['message'],
            showConfirmButton: true,
            padding: '2em',
          });
          if (this.type == 'Category' && action == 'delete') {
            let data = {
              page: 1,
              perpage: this.pageSize,
              type: "Category",
              columm: '',
              order: '',
            }
            this.common.setpagenum(data)
          }

          else if (this.type == 'SubCategory' && action == 'delete') {
            let data1 = {
              page: 1,
              perpage: this.pageSize,
              type: "SubCategory",
              columm: '',
              order: '',
            }
            // alert('hit2')
            this.common.setpagenum(data1)
          }

          else if (this.type == 'SubSubCategory' && action == 'delete') {
            let data2 = {
              page: 1,
              perpage: this.pageSize,
              type: "SubSubCategory",
              columm: '',
              order: '',
            }
            this.common.setpagenum(data2)
          }

        }
          ,
          error => {
            this.toster.error(error.error['message']);
            if (error.error['message'] == 'Unauthenticated.') {
              this.authService.clear();
            }
          }
        );

      }

    })

  }
  SendEmail() {

    Swal.fire({
      title: "Are you sure?",
      text: 'you want to send booking email again?',
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {

      console.log(result.value, "rrrr");

      if (result.value) {
        let data = {
          booking_id: this.bookid,
        }

        this.formService
          .send_booking_email(data)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (result) => {
              this.result = result

              Swal.fire({
                position: "center",

                icon: "success",
                title: this.result.message,

                showConfirmButton: false,
                timer: 2000,
                padding: "2em",
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp",
                },
              });
            }, (error) => {
              Swal.fire({
                position: "center",

                icon: "error",
                title: error.error.message,

                showConfirmButton: false,
                timer: 2000,
                padding: "2em",
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp",
                },
              });
            })



      }

    })
  }
  redirect(id, action, useid, subid) {
    this.id = id;

    if (this.type == 'Admin') {
      this.router.navigate(["admin/create-admin/" + id]);

    }
    else if (this.type == 'Pages') {
      if (useid == 'view') {
        this.router.navigate(["static-pages/add-pages/" + id + '/view']);

      } else {
        this.router.navigate(["static-pages/add-pages/" + id]);

      }
    }
    else if (this.type == 'Email') {
      this.router.navigate(["email-templates/create-template/" + id]);
    }

    else if (this.type == 'User') {
      this.router.navigate(["user/create-user/" + id]);
    }

    else if (this.type == 'Roles') {
      this.router.navigate(["role/add-roles/" + id]);
    }
    else if (this.type == 'Category') {
      this.router.navigate(["categories/create-category/" + id]);
    }
    else if (this.type == 'FormSubmission') {
      if (this.cookie.get('fzsd')) {
        this.cookie.delete('fzsd');
      }
      this.cookie.set('fzsd', action)
      this.cookie.set('useid', useid)

      this.cookie.set('bookid', id)

      this.router.navigate(["form/manage-forms/booking-details/" + id]);
    }
    else if (this.type == 'BookUser') {
      if (this.cookie.get('fzsd')) {
        this.cookie.delete('fzsd');
      }
      this.cookie.set('fzsd', action)
      this.router.navigate(["form/manage-forms/booking-details/" + id]);
    }
    else if (this.type == 'LastBookings') {
      if (this.cookie.get('fzsd')) {
        this.cookie.delete('fzsd');
      }
      this.cookie.set('fzsd', action)
      this.router.navigate(["form/manage-forms/booking-details/" + id]);
    }
    else if (this.type == 'AllBook' && this.showdetails == 'true') {
      // alert(this.book_id)
      if (this.book_id == 'votes-list') {
        if (this.cookie.get('fzsd')) {
          this.cookie.delete('fzsd');
        }
        this.cookie.set('fzsd', action)
        // --------back---------
        if (this.cookie.get('backtype')) {
          this.cookie.delete('backtype');
        }
        this.cookie.set('backtype', 'vote')
        this.router.navigate(["form/manage-forms/Voting-details/" + id]);

      } else {
        if (this.cookie.get('fzsd')) {
          this.cookie.delete('fzsd');
        }
        this.cookie.set('fzsd', action)
        // ----------back-------------
        if (this.cookie.get('backtype')) {
          this.cookie.delete('backtype');
        }
        this.cookie.set('backtype', 'book')
        // alert(this.pageSize)
        this.bookfilterdata = {
          page: this.currentPage,
          perpage: this.pageSize,
          type: "AllBook",
          columm: '',
          order: '',
          payment_type: this.payment_type,
          start_date: this.startDate,
          end_date: this.EndDate,
          search: this.MainSearchVar,
          category_name: this.category_name,
          subcategory_name: this.subcategory_name,
          sub_sub_category_name: this.sub_sub_category_name,
          discount_type: this.discount_type,
        }
        // this.common.bookfiltermainintain(data)
        if (this.cookie.get('allbookfilter')) {
          this.cookie.delete('allbookfilter')
        }
        this.cookie.set('allbookfilter', JSON.stringify(this.bookfilterdata))
        this.router.navigate(["form/manage-forms/booking-details/" + id]);

      }
    }
    else if (this.type == 'SubCategory') {
      this.editsubshow = true
      this.common.geteditSubdata(this.editsubshow)
      this.router.navigate(["categories/create-subcategory/" + id]);
    }
    else if (this.type == 'finance') {
      this.router.navigate(["finance/create-finance/" + id])
    }
    else if (this.type == 'SubSubCategory') {
      this.editsubsubshow = true
      this.common.geteditSubSubdata(this.editsubsubshow)
      this.router.navigate(["categories/create-subsubcategory/" + id]);
    }
    else if (this.type == 'HomepageSlider') {
      this.router.navigate(["homecontent/create-homepage-slider/" + id]);
    }
    else if (this.type == 'dependent') {
      this.EditDepchange.emit(true)
      localStorage.setItem('editid', id)
    }
    else if (this.type == 'FinanceRefund') {
      if(subid != "" && subid != undefined){
        this.router.navigate(["form/manage-forms/booking-details/" + subid]);
      }else{
        this.router.navigate(["admin/finance-refund-details/" + id]);      
      }
    }

  }
  BookFilter(e: any) {

    this.payment_type = e.toString()
    let data14 = {
      page: 1,
      perpage: this.pageSize,
      type: "AllBook",
      columm: '',
      order: '',
      payment_type: this.payment_type,
      start_date: this.startDate,
      end_date: this.EndDate,
      search: this.MainSearchVar,
      category_name: this.category_name,
      subcategory_name: this.subcategory_name,
      sub_sub_category_name: this.sub_sub_category_name,
      discount_type: this.discount_type,

    }
    this.common.setpagenum(data14)
  }
  AdminroleFilter(e: any) {
    let data = {
      page: 1,
      perpage: this.pageSize,
      type: "Admin",
      columm: '',
      order: '',
      filterrole: this.Adminroletype
      // search:''
    }
    this.common.setpagenum(data)

  }
  openpop(row: any, content) {

    this.cc = row.cc
    this.bcc = row.bcc
    this.emailcontent = row.content
    this.subject = row.subject
    this.to = row.to

    this.modalService.open(content, { centered: true, size: 'lg' });

  }
  subcategory(id, name) {
    this.id = id;

    localStorage.setItem('cnajs', name)
    this.router.navigate(["categories/subcategory-list/" + id]);
  }

  createsubcat(id) {
    this.editsubshow = false
    this.common.geteditSubdata(this.editsubshow)
    this.id = id
    this.router.navigate(["categories/create-subcategory/" + id]);
  }
  copytext(id) {
    navigator.clipboard.writeText(`${environment.frontEndFormUrl}/` + id)
    this.toster.success("Link Copied To Clipboard");
  }

  subsubcategory(id, name) {
    this.id = id;

    localStorage.setItem('stosbb', id)

    this.router.navigate(["categories/subsubcategory-list/" + id]);
  }

  createsubsubcat(id) {
    this.editsubsubshow = false
    this.common.geteditSubSubdata(this.editsubsubshow)
    this.id = id

    this.router.navigate(["categories/create-subsubcategory/" + id]);
  }

  showCreateForm(type) {
    if (type == 'form') {
      if (this.list.length == 0) {
        // this.cookie.set('showform','show');

      }
      else {
        this.cookie.delete('showform');
      }
      this.router.navigate(['/form/manage-forms/create-form']);

    } else {
      if (this.list.length == 0) {
        // this.cookie.set('showform','show');

      }
      else {
        this.cookie.delete('showform');
      }
      this.router.navigate(['/form/manage-forms/create-voting-form']);
    }
  }

  onSelect({ selected }) {
    if (selected.length > 0) {
      this.showstatus = true
    }
    if (selected.length == 0) {
      this.showstatus = false

    }

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  onDrag(event: any) {
    console.log(event, 'drag');
    // alert('drag')
  }

  onDrop(event: any) {
    // alert('drop')

    console.log(event, 'drop');
  }
  processAPi(type, id) {

    let data = {
      refund_id: id,
      status: ''
    }
    if (type == 'accept') {
      data.status = '1'
      this.text = 'want to accept this refund'
      this.showmess = 'Yes, Accept it!'
    }
    else {
      data.status = '-1'
      this.text = 'want to Decline this refund'
      this.showmess = 'Yes, Decline it!'


    }
    Swal.fire({
      title: 'Are you sure?',
      text: this.text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.showmess
    }).then((result) => {
      if (result.value) {
        this.AdminsService.ProcessAPi(data).pipe(takeUntil(this.destroy$)).subscribe(result => {

          Swal.fire({
            title: '',
            text: result['message'],
            icon: 'success',
            timer: 2000,
            showConfirmButton: false,
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            }
          });
          let data = {
            page: 1,
            perpage: this.pageSize,
            type: "FinanceRefund",
            columm: '',
            order: ''
          }
          this.common.setpagenum(data)
        }, error => {
          Swal.fire({
            title: '',
            text: error.error.message,
            icon: 'error',
            timer: 2000,
            showConfirmButton: false,
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            }
          });
        })

      }
    })

  }

  showEmailDetails(row: any, content2) {
    this.cc = row.cc_emails
    this.bcc = row.bcc_emails
    this.emailcontent = row.content
    this.subject = row.subject
    this.to = row.to
    this.modalService.open(content2, { centered: true });
  }
  InviteUsers(content3) {
    // this.InviteUserArr=[]

    this.modalService.open(content3, { centered: true, size: 'lg' });

  }
  openCreateEmailForm(content1) {

    // console.log(this.user_details, "On Open Details")
    const modalRef = this.modalService.open(content1, { centered: true, size: 'lg' });

    modalRef.result.then((data) => {
      this.BulkEmailForm.reset();
    }, (reason) => {
      this.BulkEmailForm.reset();
    });

  }

  userids() {
    if (this.dep_arr) {
      let arrObj = this.dep_arr.map(function (obj) {
        return obj.id;
      });
      return arrObj.toString();
    }
  }

  inviteids() {
    if (this.invite_arr) {
      let arrObj = this.invite_arr.map(function (obj) {
        return obj.id;
      });
      return arrObj.toString();
    }
  }
  sendBulkEmail() {
    if (this.BulkEmailForm.valid) {
      let data = {
        user_id: this.userids(),
        form_id: this.cookie.get('fzsd'),
        cc_emails: this.BulkEmailForm.get('cc').value,
        bcc_emails: this.BulkEmailForm.get('bcc').value,
        subject: this.BulkEmailForm.get('subject').value,
        content: this.BulkEmailForm.get('content').value,
      }

      this.formservice.createBulkEmail(data).pipe(takeUntil(this.destroy$)).subscribe(result => {
        this.result = result['data']
        Swal.fire({
          position: 'center',
          icon: 'success',
          html: result['message'],
          showConfirmButton: true,
          padding: '2em',
        });
        this.getBukEmailCalled.emit(true);
        setTimeout(() => {
          this.dep_arr = []
        }, 1000)
      }, error => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          html: error.error['message'],
          padding: '2em',
        });
      })
      this.modalService.dismissAll();
      this.BulkEmailForm.reset();

    }
    else {
      for (let i in this.BulkEmailForm.controls) {
        this.BulkEmailForm.controls[i].markAsTouched();
      }
    }
  }

  getBookingUsers() {
    // let data = {
    //   form_id: this.cookie.get('fzsd')
    // }
    let data = {
      form_id: this.form_id2
    }


    this.formservice.getBookingUsers(data).pipe(takeUntil(this.destroy$)).subscribe(result => {
      this.DepEmaildata = result['data'];
      this.DepEmaildata.map((x) => {
        this.user_details.push(x.user_detail)
      });
    }, error => {
      this.toster.error(error.error['message']);
    })
  }

  onItemSelect(item: any) {
    // console.log(item, "ITEM ON SELECT")
    if (this.dep_arr.includes(item.id) === false) {
      this.dep_arr.push({ 'id': item.id });
    }
  }
  onItemInviteSelect(item: any) {
    // console.log(item, "ITEM ON SELECT")
    this.invite_err = ''
    if (this.invite_arr.includes(item.id) === false) {
      this.invite_arr.push({ 'id': item.id });
    }
  }
  onSelectAll(items: any) {
    this.invite_err = ''

    this.dep_arr = items
  }

  onDeSelect(ite: any) {
    this.dep_arr = this.dep_arr.filter((el) => {
      return el.id != ite.id
    });

  }

  onDeSelectAll(deitems: any) {
    this.dep_arr = deitems
  }

  onSelectInviteAll(items: any) {
    this.invite_arr = items
  }

  onDeSelectInvite(ite: any) {
    this.invite_arr = this.invite_arr.filter((el) => {
      return el.id != ite.id
    });

  }

  onDeSelectAllInvite(deitems: any) {
    this.invite_arr = deitems
  }
  Inviteapihit() {
    if (this.InviteUserdata.length > 0) {
      let data = {
        "user_ids": this.inviteids(),
        "form_id": this.form_id
      }
      // alert('Invite Hit')
      this.LoaderClass = true
      this.modalService.dismissAll()
      this.AdminsService.postInviteUser(data).pipe(takeUntil(this.destroy$)).subscribe(result => {
        this.LoaderClass = false

        Swal.fire({
          title: '',
          text: 'Email Sent Successfully',
          icon: 'success',
          timer: 2000,
          showConfirmButton: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        });
        this.getFormdata.emit(true)
        this.InviteUserdata = []
        this.invite_arr = []

        let data = {
          form_id: this.form_id,
        };
        this.formService.getInviteUser(data).pipe(takeUntil(this.destroy$)).subscribe((result) => {
          this.InviteUserArr = result['data'];
        })
        let data4 = {
          page: 1,
          perpage: this.pageSize,
          type: "Page",
          columm: '',
          order: ''
        }
        this.modalService.dismissAll()
      })
    } else {
      this.invite_err = "This fied is required"
    }


  }
}
