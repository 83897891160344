import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { ApiClientService } from './api-client.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  userPermissions$ = new BehaviorSubject<any[]>([]);

  formBuilderValidationRulesCustomizer = new Subject<any>();
  //  ArrayData=new BehaviorSubject<any[]>([]);
   urlroute=new BehaviorSubject<any>('')
   getapicall = new BehaviorSubject<any>('')
  pagenum=new BehaviorSubject<any>('')
  searchval=new BehaviorSubject<any>('')
  subidpass=new BehaviorSubject<any>('')
delid=new BehaviorSubject<any>('')
delformid=new BehaviorSubject<any>('')
delfinid=new BehaviorSubject<any>('')
permarr=new BehaviorSubject<any>([])
pageper=new BehaviorSubject<any>('')
subsubidpass=new BehaviorSubject<any>('')
sortcol = new BehaviorSubject<any>('')
duplicateform=new BehaviorSubject<any>('')
bookid=new BehaviorSubject<any>('')
natpass=new BehaviorSubject<any>('')
date=new BehaviorSubject<any>('')
amount=new BehaviorSubject<any>('')
status=new BehaviorSubject<any>('')
type=new BehaviorSubject<any>('')
catname=new BehaviorSubject<any>('')
resetfil=new BehaviorSubject<any>('')
Permissions=new BehaviorSubject<any>([])
profilename=new BehaviorSubject<any>('')
// createdep=new BehaviorSubject<any>('')
  private editsubData = new BehaviorSubject<any>('');
  EditSub = this.editsubData.asObservable();
  private editsubsubData = new BehaviorSubject<any>('');
  EditSubSub = this.editsubsubData.asObservable();
preconfigure = new BehaviorSubject<any>('');
price=new BehaviorSubject<any>('');
openpagerule=new BehaviorSubject<any>([]);
deletepageindex=new BehaviorSubject<any>(''); //
hitformapi=new BehaviorSubject<any>('')
prohit=new BehaviorSubject<any>('')
// filtermaintainer=new BehaviorSubject<any>({})
filterhits=new BehaviorSubject<any>('')
guestFilter=new BehaviorSubject<any>('')
paginationupdate=new BehaviorSubject<any>({})
  constructor( public toster: ToastrService,
    private apiClientService: ApiClientService,
    private router:Router) {
    // console.log("getapi", this.getapicall);
      
     }
    // bookfiltermainintain(data:any){
    //   this.filtermaintainer.next(data);
    // }
    // getBookFiltermaintainer(){
    //   return this.filtermaintainer.asObservable()

    // }
    paginationhit(data:any){
      this.paginationupdate.next(data)

    }
    getpaginationhit(){
      return   this.paginationupdate.asObservable()

    }
    guestexecuteFilter(data:any){
      this.guestFilter.next(data)
          }
     getguestexecutefilter(){
            return this.guestFilter.asObservable()
          }
    executeFilter(data:any){
this.filterhits.next(data)
    }
    getexecutefilter(){
      return this.filterhits.asObservable()
    }
     setpagerule(perm:any){
this.openpagerule.next(perm)
     }

     setIndex(ind:any){
      this.deletepageindex.next(ind)
     }
     RehitFormApi(hit:any){
      this.hitformapi.next(hit)

     }
     getIndex(){
      return this.deletepageindex.asObservable()
     }
     getpagerule(){
     return this.openpagerule.asObservable()
     }
     setprofilename(perm:any){
      this.profilename.next(perm)
     }
     getprofilename(){
    return  this.profilename.asObservable()
     }
     setpermissions(perm:any){
      this.Permissions.next(perm)
     }
     getpermissions(){
    return  this.Permissions.asObservable()
     }
     setprofilehit(perm:any){
      this.prohit.next(perm)
     }
     getprofilehit(){
    return  this.prohit.asObservable()
     }
     setprice(price: any) {
       this.price.next(price);
       }
       getprice() {
       return this.price.asObservable()
       }
setcat(cat){
  this.catname.next(cat)
}
// setcreatedep(dep){
//   this.createdep.next(dep)
// }
setpreconfigure(data:any){
  this.preconfigure.next(data)
}
getpreconfigure(){
return this.preconfigure.asObservable()
}
setreset(res){
  this.resetfil.next(res)
}
    settype(typ){
      this.type.next(typ)
    }
    setstatus(stat){
      this.status.next(stat)
    }
    setamount(am){
      this.amount.next(am);
    }
    setdate(date){
      this.date.next(date)
    }
    setnat(nat){
      this.natpass.next(nat)
    }
    setdup(dup){
      this.duplicateform.next(dup)
    }
    setcol(col){
      this.sortcol.next(col)
    }
    setpageper(num){
      this.pageper.next(num)
    }
    setsubid(cal){
      this.subidpass.next(cal)
    }
    setsubsubid(cal){
      this.subsubidpass.next(cal)
    }

    geteditSubdata(v:any){
      this.editsubData.next(v)
    }

    geteditSubSubdata(v:any){
      this.editsubsubData.next(v)
    }
    setpermarr(arr){
      this.permarr.next(arr)
    }
    setdel(del){
      this.delid.next(del)
    }
    setformdel(del){
      this.delformid.next(del)
    }
    setfinancedel(del){
      this.delfinid.next(del)
    }
setsearch(cal){
  this.searchval.next(cal)
}
  setUserPermissions(value) {
    this.userPermissions$.next(value);
  }
  setpagenum(data){
    return this.pagenum.next(data)
  }
// setData(value){
//   return this.ArrayData.next(value)
// }
seturl(url){
  return this.urlroute.next(url)
}
setapi(val){
  return this.getapicall.next(val)

}
  getUserPermissions() {
    return this.userPermissions$.asObservable();
  }
  CheckUnique(data: any) {
      return this.apiClientService.post('unique_email', data);
  }
  CreateUserForm(data) {
    
    this.apiClientService.post('users', data).subscribe(result => {
      if(result && result['success']==true) {
        this.toster.success(result['message']);
        this.router.navigate(['/user/user-listing']);

      }
    },error =>{
      this.toster.error(error.error['message']);
    })
  }
  UpdateUserForm(data,id) {
    
    this.apiClientService.post('users/'+id, data).subscribe(result => {
      if(result && result['success']==true) {
        // this.toster.success(result['message']);
        this.router.navigate(['/user/user-listing']);
        Swal.fire({
          position: 'center',
          icon: 'success',
          html: result['message'],
          showConfirmButton: true,
          padding: '2em',
        });
      }
    },error =>{
      // this.toster.error(error.error['message']);
      Swal.fire({
        position: 'center',
        icon: 'error',
        html: error.error['message'],
        showConfirmButton: true,
        padding: '2em',
      });
    })
  }
  
  resetUserForm(data,id) {
    
    this.apiClientService.post('users/'+id, data).subscribe(result => {
      if(result && result['success']==true) {
        this.toster.success(result['message']);
        this.router.navigate(['/user/user-listing']);

      }
    },error =>{
      this.toster.error(error.error['message']);
    })
  }



  getusereditdata(data){
  return  this.apiClientService.get('users/'+data.id+'/edit')

  }
  getdependenteditdata(data){
  return  this.apiClientService.post('dependent_detail',data)

  }
  getEmailsList(data){
    return this.apiClientService.post('booking_auto_emails',data)
  
    }
  getbookingdata(id,rows,page,col,ord){
    if(rows =='All'){
      rows = '';
    }
    return this.apiClientService.get('get_user_submission/'+id +'?rows='+rows +'&page=' +page+'&col='+col +'&order='+ ord )
 
  }
  dependentlist(data:any,rows,page,col,ord){
    return this.apiClientService.post('dependent_list?rows='+rows +'&page=' +page+'&col='+col +'&order='+ ord,data )
  }

  getdependentsEmailList(data:any){
    return this.apiClientService.post('dependent_list',data)
  }
  DiscountFileProof(file:any){
    return this.apiClientService.post('save_discount_proof',file)
 
}
  setFormBuilderValidationRulesCustomizer(data) {
    this.formBuilderValidationRulesCustomizer.next(data);
  }
  getFormBuilderValidationRulesCustomizer() {
    return this.formBuilderValidationRulesCustomizer.asObservable();
  }
  CountryList(){
    return this.apiClientService.get('countrylist' );

  }
  getRoles(rows,page,col,ord){
    return this.apiClientService.get('roles?rows='+rows +'&page=' +page+'&col='+col+'&order='+ord);
  }
  getAdminRolesFilter(){
    return this.apiClientService.get('roles');
  }
  // 'admins?rows='+rows +'&page=' +page
  editRoles(data){
    return this.apiClientService.get('roles/' +data+'/edit' );

  }
  getPermissionsList(){
    return this.apiClientService.get('roles/create' );

  }
  createRole(data){

    this.apiClientService.post('roles',data).subscribe(result => {
      if(result && result['success']==true) {
        this.toster.success(result['message']);
        this.router.navigate(['/role/all-roles']);

        // alert(result['message'])

      }
    },error =>{
      this.toster.error(error.error['message']);
    })
  }
  UpdateRole(id,data){

    this.apiClientService.put('roles/'+id,data).subscribe(result => {
      if(result && result['success']==true) {
        this.toster.success(result['message']);
        this.router.navigate(['/role/all-roles']);

        // alert(result['message'])

      }
    },error =>{
      this.toster.error(error.error['message']);
    })
  }
  getPermissionAccess(data){
    return this.apiClientService.get('roles/' +data );

  }
  GetAllBookings(rows,page,col,ord,type,paytype,start_date,end_date,search,category_name,subcategory_name,sub_sub_category_name,discount_type) {
   
    return this.apiClientService.get('all_bookings?rows='+rows +'&page=' +page+'&col='+col +'&order='+ ord + '&form_type=' +type + '&payment_type=' +paytype+ '&start_date=' +start_date+ '&end_date=' +end_date+'&search='+search+'&category_name='+category_name+'&subcategory_name='+subcategory_name+'&sub_sub_category_name='+sub_sub_category_name+"&discount_type="+discount_type);

  
}
fetchVotingForms() {
  return this.apiClientService.get('voting_forms');
}
getFilterBook() {
   
  return this.apiClientService.get('get_payment_filter_value')

}
FinanceType() {
   
  return this.apiClientService.get('finance_category');


}
FinanceTypeForForms() {
   
  return this.apiClientService.get('form_finance_category');


}
getActiveConfiguredEmails() {
  return this.apiClientService.get('get_active_emails');
}
additionalamount(amt:any){
  return this.apiClientService.post('additional_payment',amt)

}
RefundAmount(amt:any){
  return this.apiClientService.post('refund_payment',amt)

}
MiddleWareLogsFetch(bookid:any){
  return this.apiClientService.post('log',bookid)

}
getFinanceRefundList(rows,page,col,ord){
  return this.apiClientService.get('pending_refunds?rows='+rows +'&page=' +page+'&col='+col +'&order='+ ord);  


}
getSingleFinanceRefund(id){
  return this.apiClientService.get('pending_refunds/'+id);  


}
BookFormDataChange(data){
  return this.apiClientService.put('update_booking_userinfo',data)
}
checkEmail(data){
  return  this.apiClientService.post('email_validation',data)

  }
  sendEmail(data) {
    this.apiClientService.post('send_email', data).subscribe(result => {
      if(result && result['success']==true) {
        this.toster.success(result['message']);
        this.router.navigate(['user/user-listing']);
      }
    },error =>{
      this.toster.error(error.error['message']);
    })
  }

  getAllCategoriesListForRole(roleID: any) {
    let apiUrl = roleID != '' ? 'category_lists/'+roleID : 'category_lists';
    return this.apiClientService.get(apiUrl)
  }
}


