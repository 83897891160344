import { Injectable, NgZone } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import { PLATFORM_ID, Inject } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";

import { environment } from '../../../environments/environment';
import { EMPTY } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ApiClientService {
  constructor(
    private http: HttpClient,
    private zone: NgZone,
    private router: Router,
    @Inject(PLATFORM_ID) platformId: string
  ) {}

  private prependApiUrl(url: string): string {
    return environment.baseApiUrl + '/' + url;
  }

  get<T>(url: string, options: { baseURL?: boolean; params?: HttpParams; signal?: AbortSignal } = {}): Observable<T> {
    const { baseURL = true, params, signal } = options;
    const finalUrl = baseURL ? this.prependApiUrl(url) : url;
    const httpOptions: any = { params };

    const abortObservable = signal
      ? new Observable<void>((subscriber) => {
          signal.addEventListener('abort', () => {
            subscriber.next();
            subscriber.complete();
          });
        })
      : EMPTY;

    if (signal) {
      httpOptions.signal = signal;
    }

    return this.http.get<T>(finalUrl, httpOptions).pipe(
      takeUntil(abortObservable), 
      map((response) => this.handleSuccess(response)) 
    );
  }

  post<T>(url: string, body: any, signal?: AbortSignal): Observable<T> {
    const abortObservable = signal
      ? new Observable<void>((subscriber) => {
          signal.addEventListener('abort', () => {
            subscriber.next();
            subscriber.complete();
          });
        })
      : EMPTY;

    return this.http.post<T>(this.prependApiUrl(url), body).pipe(
      takeUntil(abortObservable), 
      map((response) => this.handleSuccess(response)) 
    );
  }

  put<T>(url: string, body?: any): Observable<T> {
    return this.http.put(this.prependApiUrl(url), body).map((response) => this.handleSuccess(response));
  }

  delete<T>(url: string): Observable<T> {
    return this.http.delete(this.prependApiUrl(url)).map((response) => this.handleSuccess(response));
  }

  patch(url: string, body: any): Observable<Object> {
    return this.http.patch(this.prependApiUrl(url), body);
  }

  head(url: string): Observable<Object> {
    return this.http.head(this.prependApiUrl(url));
  }

  options(url: string): Observable<Object> {
    return this.http.options(this.prependApiUrl(url));
  }

  private handleSuccess<T>(res): T {
    return res;
  }
}
