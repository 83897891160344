<div class="container-fluid">
  <!-- Header Section -->
  <div class="row mb-3">
    <div class="col-md-6">
      <h2>List of Emails</h2>
    </div>
    <div class="col-md-6 text-md-end">
      <div class="d-flex justify-content-end align-items-center">
        <!-- <input
          type="text"
          class="form-control w-auto me-2"
          placeholder="Search"
          [(ngModel)]="searchTerm"
        /> -->
        <button class="btn btn-primary" (click)="openEmailModal(createEmailModal)">
          Add Email
        </button>
      </div>
    </div>
  </div>

  <!-- Table Section -->
  <div class="row">
    <div class="col-12">
      <div class="table-responsive">
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Send From Email</th>
              <th>Default</th>
              <th>Tenant</th>
              <th>Client Id</th>
              <th>Client Secret</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let email of emailList; let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ email.send_from }}</td>
              <td>{{ email.default }}</td>
              <td>{{ email.tenant }}</td>
              <td>{{ email.client_id }}</td>
              <td>{{ email.client_secret }}</td>
              <td>
                <span
                  class="badge"
                  [class.bg-success]="email.status === 1"
                  [class.bg-danger]="email.status === 0"
                >
                  {{ email.status == '1' ? 'Activated' : 'Deactivated' }}
                </span>
              </td>
              <td>
                <!-- Actions -->
                <button
                  class="btn btn-success btn-sm"
                  (click)="openEmailModal(confirmEmailModal, email.id, 'Are you sure to activate this email?', 1)"
                  *ngIf="email.status === 0" ngbTooltip="Activate"
                >
                  <i class="fa fa-check-circle"></i>
                </button>
                <button
                  class="btn btn-danger btn-sm"
                  (click)="openEmailModal(confirmEmailModal, email.id, 'Are you sure to deactivate this email?', 2)"
                  *ngIf="email.status === 1" ngbTooltip="Deactivate"
                >
                  <i class="fa fa-ban"></i>
                </button>
                <!-- <button *ngIf="email.default == 'no'"
                  class="btn btn-primary btn-sm"
                  (click)="openEmailModal(confirmEmailModal, email.id, 'Are you sure to set this as a default email?', 3)"
                >
                  <i class="fa fa-edit"></i>
                </button> -->
                <button
                  class="btn btn-primary btn-sm"
                  (click)="editEmail(createEmailModal, email)"
                  ngbTooltip="Edit"
                >
                  <i class="fa fa-edit"></i>
                </button>
                <button
                ngbTooltip="Delete"
                  class="btn btn-danger btn-sm"
                  (click)="openEmailModal(confirmEmailModal, email.id, 'Are you sure to delete this email?', 4)"
                >
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
            <tr *ngIf="!emailList.length">
              <td colspan="6" class="text-center">No records found</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!-- Add Email Modal -->
  <ng-template #createEmailModal let-modal>
    <div class="modal-header">
      <ng-container *ngIf="isEditView; else addHeadingBlock">
        <h5 class="modal-title">Update Email</h5>
      </ng-container>
      <ng-template #addHeadingBlock>
        <h5 class="modal-title">Add Email</h5>
      </ng-template>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      ></button>
    </div>
    <form [formGroup]="emailForm" (ngSubmit)="onSubmit(modal)">
      <div class="modal-body">
        <div class="mb-3">
          <label for="sendFrom" class="form-label">Send From</label>
          <input
            type="email"
            id="sendFrom"
            class="form-control"
            formControlName="send_from"
            placeholder="Enter email"
          />
          <label for="tenant" class="form-label">Tenant</label>
          <input
            type="text"
            id="tenant"
            class="form-control"
            formControlName="tenant"
            placeholder="Tenant"
          />
          <label for="clientId" class="form-label">Client ID</label>
          <input
            type="text"
            id="clientId"
            class="form-control"
            formControlName="client_id"
            placeholder="Client Id"
          />
          <label for="clientSecret" class="form-label">Client Secret</label>
          <input
            type="text"
            id="clientSecret"
            class="form-control"
            formControlName="client_secret"
            placeholder="Client Secret"
          />
          <div *ngIf="emailForm.get('send_from')?.touched && emailForm.get('send_from')?.invalid" class="text-danger">
            <small *ngIf="emailForm.get('send_from')?.errors?.required">Email is required.</small>
            <small *ngIf="emailForm.get('send_from')?.errors?.email">Enter a valid email.</small>
          </div>
        </div>
        <div class="form-check">
          <input
            type="checkbox"
            id="defaultEmail"
            class="form-check-input"
            formControlName="default"
          />
          <label for="defaultEmail" class="form-check-label">Set as Default Email</label>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="modal.dismiss('Cancel click')"
        >
          Cancel
        </button>
        <button type="submit" class="btn btn-primary" [disabled]="emailForm.invalid || disableSubmit">
          <ng-container *ngIf="isEditView; else submitButtonBlock">
            Update
          </ng-container>
          <ng-template #submitButtonBlock>
            Submit
          </ng-template>
        </button>
      </div>
    </form>
  </ng-template>

  <!-- Confirmation Modal -->
  <ng-template #confirmEmailModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title">{{ action_email_message }}</h5>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      ></button>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="modal.dismiss('Cancel click')"
      >
        Cancel
      </button>
      <button class="btn btn-primary" (click)="onEmailAction(action_email_id, action, modal)">
        Yes
      </button>
    </div>
  </ng-template>
</div>
